import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Grid, Typography, Button, LinearProgress, Paper, TextField as MuiTextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";
import { handleCreateBanner, handleEditBanner } from "../../actions/bannerActions";
import { Link } from "react-router-dom";
import { Autocomplete } from "formik-material-ui-lab";

import * as Yup from "yup";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
    padding: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(2),
  },
  select: {
    margin: theme.spacing(2),
    width: "100%",
  },
});

const newsItemSchema = Yup.object().shape({
  url: Yup.string().url("Invalid Url"),
  message: Yup.string().min(10, "Too Short").max(120, "Too Long").required("Required"),
});

export class BannerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: true,
      message: "",
      url: "",
      isEdit: false,
      duration: [],
      audience: [],
      clients: []
    };
  }

  componentDidMount() {
    if (this.props.match.params.id !== null) {
      // needs to be == as params.id = "2" and id = 2 for example.
      let editItem = this.props.banners.find((n) => n.id == this.props.match.params.id);
      if (editItem) {
        this.setState({
          loading: false,
          message: editItem.message,
          url: editItem.url,
          duration: editItem.duration,
          audience: editItem.audience,
          clients: editItem.clients,
          isEdit: true,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    let { classes, audienceOptions, durationOptions, clientOptions } = this.props;
    let { loading, message, url, duration, audience, clients, isEdit } = this.state;
    const formikRef = React.createRef();

    // see https://github.com/jaredpalmer/formik/issues/33
    const onSubmit = (values) => {
      try {

        values.audience = this.state.audience;
        values.duration = this.state.duration;
        values.clients = this.state.clients;

        if (isEdit) {
          values.id = this.props.match.params.id;
          this.props.handleEditBanner(this.props.match.params.id, values);
        } else {
          this.props.handleCreateBanner(values);
        }
      } catch (apiException) {
        if (apiException.response && apiException.response.data && apiException.response.data.error) {
          formikRef.current.setErrors(apiException.response.data.error);
        } else {
          console.error(apiException); // or some other fallback handling
        }
      }
    };

    const cancelCreate = () => {};
    
    if (loading) {
      return <div>loading...</div>;
    } else {
      return (
        <Paper>
          <Grid className={classes.root} container spacing={3}>
            <Formik
              onSubmit={onSubmit}
              cancelCreate={cancelCreate}
              ref={formikRef}
              initialValues={{ 
                message: message, 
                url: url,
                audience : audience,
                duration: duration,
                clients: clients
              }}
              validationSchema={newsItemSchema}>
              {({ isSubmitting, submitForm, touched, errors }) => (
                <Form>
                  <div>
                    <Typography variant="h5">{isEdit ? "Edit Banner" : "Create a new Banner"}</Typography>
                  </div>
                  <Field
                    inputProps={{ maxLength: 120 }}
                    component={TextField}
                    className={classes.textField}
                    fullWidth
                    multiline
                    rows="3"
                    maxLength={120}
                    type="text"
                    name="message"
                    variant="outlined"
                    label="Banner message"
                  />
                  <Field
                    component={TextField}
                    className={classes.textField}
                    fullWidth
                    type="text"
                    name="url"
                    variant="outlined"
                    label="Banner Url"
                  />
                  <Field
                    name="clients"
                    multiple
                    className={classes.select}
                    component={Autocomplete}
                    options={clientOptions}
                    value={clients}
                    onChange={(event, newValue) => {
                      this.setState({
                        clients: newValue
                      });
                    }}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched["clients"] && !!errors["clients"]}
                        helperText={touched["clients"] && errors["clients"]}
                        label="clients"
                        variant="outlined"
                      />
                    )}
                  />
                  <Field
                    name="audience"
                    multiple
                    className={classes.select}
                    component={Autocomplete}
                    options={audienceOptions}
                    filterSelectedOptions
                    value={audience}
                    onChange={(event, newValue) => {
                      this.setState({
                        audience: newValue
                      });
                    }}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched["audience"] && !!errors["audience"]}
                        helperText={touched["audience"] && errors["audience"]}
                        label="Audience"
                        variant="outlined"
                      />
                    )}
                  />
                  <Field
                    name="duration"
                    className={classes.select}
                    component={Autocomplete}
                    options={durationOptions}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.label === value.label}
                    filterSelectedOptions
                    value={duration}
                    onChange={(event, newValue) => {
                      this.setState({
                        duration: newValue
                      });
                    }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched["duration"] && !!errors["duration"]}
                        helperText={touched["duration"] && errors["duration"]}
                        label="Duration"
                        variant="outlined"
                      />
                    )}
                  />
                  <br />
                  {isSubmitting && <LinearProgress />}
                  <br />
                  <Button
                    className={classes.menuButton}
                    component={Link}
                    to="/BannerSettings"
                    variant="text"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={cancelCreate}>
                    Cancel
                  </Button>
                  <Button
                    className={classes.menuButton}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}>
                    SAVE
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </Paper>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { banner } = state;
  return {
    loading: banner.loading,
    banners: banner.banners,
    durationOptions: banner.durationOptions,
    audienceOptions: banner.audienceOptions,
    clientOptions: banner.clientOptions
  };
};

const mapDispatchToProps = {
  handleCreateBanner,
  handleEditBanner,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(BannerForm));
