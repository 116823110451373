import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Card, Typography, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, Link } from '@material-ui/core';
import Loading from '../Shared/Loading';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import People from '@material-ui/icons/People';
import Face from '@material-ui/icons/Face';
import { loadDashboardData } from '../../actions/dashboardActions';
import Moment from 'react-moment';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	menuButton: {
		margin: theme.spacing(2),
	},
	gridRoot: {
		minHeight: 'inherit',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
	},
	textField: {
		margin: theme.spacing(2),
	},
	cardContainer: {},
	card: {
		minHeight: '225px',
		display: 'flex',
		overflow: 'visible',
	},
	heading: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(4),
	},
	tableHeader: {
		margin: theme.spacing(2),
	},
	media: {
		height: 125,
		width: 125,
		float: 'left',
		padding: '15px',
		marginTop: '-20px',
		marginRight: '20px',
		marginLeft: '20px',
		borderRadius: '10px',
		background: 'linear-gradient(60deg, rgb(6 167 226), rgb(97 191 239))',
		boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgb(6, 167, 226, 0.4)',
	},
	mediaIcon: {
		width: '100%',
		height: '100%',
		color: '#fff',
	},
	controls: {
		display: 'flex',
		width: '100%',
	},
	content: {
		flex: '1 0 auto',
		textAlign: 'center',
		padding: 0,
	},
	cardGrid: {
		display: 'flex',
	},
	tableContainer: {
		height: '100%',
		padding: theme.spacing(2),
	},
	gridItem: {
		marginBottom: '30px',
	},
});

const Container = styled.div`
	min-height: calc(100vh - 132px);
	overflow: hidden;
`;

export class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rowsPerPage: 10,
			page: 0,
		};
	}
	componentDidMount() {
		this.props.loadDashboardData();
		this.timer = setInterval(() => this.props.loadDashboardData(), 10000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		this.timer = null;
	}

	handleChangePage = (event, newPage) => {
		this.setState({
			page: newPage,
		});
	};

	handleChangeRowsPerPage = (event) => {
		this.setState({
			rowsPerPage: event.target.value,
			page: 0,
		});
	};

	render() {
		let { loading, classes, onlineCount, ositCount, totalCount, ositUsers } = this.props;
		let { page, rowsPerPage } = this.state;

		if (loading) {
			return <Loading />;
		} else {
			return (
				<Container>
					<Grid container spacing={3} direction='row' alignItems='stretch'>
						<Grid item xs={9}>
							<Typography gutterBottom color='textPrimary' className={classes.heading} variant='h4'>
								Deployment Overview
							</Typography>
						</Grid>
						<Grid item xs={3} style={{ marginTop: '8px' }}>
							<Button style={{ float: 'right' }} component={RouterLink} to='/clientUsage' variant='contained' color='primary'>
								Deployment Dashboard
							</Button>
						</Grid>
						<Grid container item xs={12} sm={6} spacing={3}>
							<Grid item xs={12} className={classes.gridItem}>
								<Card className={classes.card}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<CardContent className={classes.media}>
												<RecordVoiceOver className={classes.mediaIcon} />
											</CardContent>
										</Grid>
										<Grid item xs={6} alignItems='center' className={classes.cardGrid}>
											<div className={classes.controls}>
												<CardContent className={classes.content}>
													<Typography variant='h2' component='h2'>
														{onlineCount}
													</Typography>
													<Typography gutterBottom variant='h6' component='p'>
														Online Users
													</Typography>
												</CardContent>
											</div>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid item xs={12} className={classes.gridItem}>
								<Card className={classes.card}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<CardContent className={classes.media}>
												<People className={classes.mediaIcon} />
											</CardContent>
										</Grid>
										<Grid item xs={6} alignItems='center' className={classes.cardGrid}>
											<div className={classes.controls}>
												<CardContent className={classes.content}>
													<Typography variant='h2' component='h2'>
														{totalCount}
													</Typography>
													<Typography gutterBottom variant='h6' component='p'>
														Total Users
													</Typography>
												</CardContent>
											</div>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid item xs={12}>
								<Card className={classes.card}>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<CardContent className={classes.media}>
												<Face className={classes.mediaIcon} />
											</CardContent>
										</Grid>
										<Grid item xs={6} alignItems='center' className={classes.cardGrid}>
											<div className={classes.controls}>
												<CardContent className={classes.content}>
													<Typography variant='h2' component='h2'>
														{ositCount}
													</Typography>
													<Typography gutterBottom variant='h6' component='p'>
														OSIT Users
													</Typography>
												</CardContent>
											</div>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6} alignItems='stretch'>
							<TableContainer component={Paper} className={classes.tableContainer}>
								<Typography gutterBottom variant='h5' className={classes.tableHeader}>
									Logged in Team Members
								</Typography>
								<Table className={classes.table} aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell>User</TableCell>
											<TableCell align='right'>Last Login</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{ositUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
											<TableRow key={user.name}>
												<TableCell component='th' scope='row'>
													{user.name}
												</TableCell>
												<TableCell align='right'>
													<Moment format='DD/MM/YYYY hh:mm' utc local>
														{user.lastLogin}
													</Moment>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
								<TablePagination
									colSpan={2}
									rowsPerPageOptions={[5, 10, 25]}
									component='div'
									count={ositUsers.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</TableContainer>
						</Grid>
					</Grid>
				</Container>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const { dashboard } = state;
	return {
		loading: dashboard.loading,
		onlineCount: dashboard.onlineCount,
		ositCount: dashboard.ositCount,
		totalCount: dashboard.totalCount,
		ositUsers: dashboard.ositUsers,
	};
};

const mapDispatchToProps = {
	loadDashboardData,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
