import React from 'react'
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

const headerCells = [
    { id: 'name', label: 'Company', numeric: false},
    { id: 'approvalRequired', label: 'Approval Required', numeric: false},
    { id: 'team', label: 'Team', numeric: false},
  ];

export default function EnhancedTableHead(props) {

    const {
        classes,
        order,
        orderBy,
        onRequestSort
      } = props;

      const createSortHandler = property => event => {
        onRequestSort(event, property);
      };
    
    return (
			<TableHead className={classes.tableHeader}>
				<TableRow>
					<React.Fragment>
						{headerCells.map(headCell => (
								<TableCell
										key={headCell.id}
										align={headCell.numeric ? "right" : "left"}
										padding={headCell.disablePadding ? "none" : "default"}
										sortDirection={orderBy === headCell.id ? order : false}
								>
								<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id)}
								>
										{headCell.label}
										{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden} />
										) : null}
								</TableSortLabel>
								</TableCell>
						))}
						<TableCell>
								Details
						</TableCell>
					</React.Fragment>
				</TableRow>
		</TableHead>
    )
}
