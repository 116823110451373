import { 
  CONFIG_TYPES_LOADING,
  CONFIG_TYPES_LOADED,
  CONFIG_QUESTIONS_LOADING,
  CONFIG_QUESTIONS_LOADED,
  CONFIG_DATA_LOADING,
  CONFIG_DATA_LOADED,
} from '../actions/types/clientConfigsTypes';

const initialState = {
  loading: true,

  configTypes: [],
  loadingConfigQuestions: false,
  configQuestions: [],
  configData: []
};

const clientConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_DATA_LOADING:
    return Object.assign({}, state, {
        loading: true,
    });
    case CONFIG_DATA_LOADED:
    return Object.assign({}, state, {
        configData: action.payload,
        loading: false,
    });
    case CONFIG_TYPES_LOADING:
    return Object.assign({}, state, {
      loading: true
    });
    case CONFIG_TYPES_LOADED:
      return Object.assign({}, state, {
          loading: false,
          configTypes: action.payload,
      });
    case CONFIG_QUESTIONS_LOADING:
    return Object.assign({}, state, {
      loadingConfigQuestions: true,
    });
    case CONFIG_QUESTIONS_LOADED:
    return Object.assign({}, state, {
        configQuestions: action.payload,
        loadingConfigQuestions: false,
    });
    default:
      return state;
  }
};

export default clientConfigsReducer;
