export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_LOADING_ERROR = 'ACCOUNT_LOADING_ERROR';
export const ACCOUNT_SAVED = 'ACCOUNT_SAVED';
export const ACCOUNT_SAVING = 'ACCOUNT_SAVING';
export const ACCOUNT_SAVING_ERROR = 'ACCOUNT_SAVING_ERROR';
export const ACCOUNT_PROFILE_LOADED = 'ACCOUNT_PROFILE_LOADED';
export const ACCOUNT_SAVE_ACK = 'ACCOUNT_SAVE_ACK';
export const ACCOUNT_DARK_MODE_TOGGLE = 'ACCOUNT_DARK_MODE_TOGGLE';
export const USER_ACCOUNT_LOADED = 'USER_ACCOUNT_LOADED';
export const USER_ACCOUNT_SAVED = 'USER_ACCOUNT_SAVED';