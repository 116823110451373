import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
}));

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

export default function Loading() {
  const classes = useStyles();

  return (
    <Container>
      <Paper item xs={12} className={classes.root}>
        <Grid className={classes.root} container>
          <Grid container justify="center" alignItems="center" item xs={12} className={classes.gridRoot}>
            <CircularProgress size={96} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
