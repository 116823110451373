import React from "react";
import { ListItem, ListItemText, Checkbox, Button, CircularProgress } from "@material-ui/core";

const OverrideListItem = ({ divider, client, updateBroadcast, updateNotifications, updateTracking, updateAfterHoursWarning, updateQuotesToManagement, handleSave }) => {
  return (
    <ListItem divider={divider}>
      <ListItemText primary={client.name} />
      <Checkbox checked={client.broadcastsEnabled} onClick={() => updateBroadcast(client.id)} />
      <Checkbox checked={client.notificationsEnabled} onClick={() => updateNotifications(client.id)} />
      <Checkbox checked={client.userTrackingEnabled} onClick={() => updateTracking(client.id)} />
      <Checkbox checked={client.afterHoursWarningEnabled} onClick={() => updateAfterHoursWarning(client.id)} />
      <Checkbox checked={client.quotesToManagementEnabled} onClick={() => updateQuotesToManagement(client.id)} />
      <Button disabled={client.saving} onClick={() => handleSave(client.id, client)} color="primary">
        {client.saving ? <CircularProgress size={24} color="primary" /> : "SAVE"}
      </Button>
    </ListItem>
  );
};

export default OverrideListItem;
