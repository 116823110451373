import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger";
import rootReducer from "../reducers/rootReducer";
import signalRMiddleware from "../middlewares/signalRMiddleware";
import { throttle } from "lodash";
import { saveState, loadState } from "./localStorage";

export const history = createBrowserHistory();
const initialState = loadState();

function configureStore() {
  const middleware = [thunk, routerMiddleware(history), logger, signalRMiddleware];

  const enhancers = [];

  let store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
  );

  store.subscribe(
    throttle(() => {
      //saveState(store.getState());
    }, 5000)
  );

  return store;
}

export const store = configureStore();
