import {
	ACCOUNT_LOADING,
	ACCOUNT_LOADED,
	ACCOUNT_LOADING_ERROR,
	ACCOUNT_SAVED,
	ACCOUNT_SAVING,
	ACCOUNT_SAVING_ERROR,
	ACCOUNT_PROFILE_LOADED,
	ACCOUNT_SAVE_ACK,
	ACCOUNT_DARK_MODE_TOGGLE,
	USER_ACCOUNT_LOADED,
	USER_ACCOUNT_SAVED,
} from './types/accountTypes';
import api from '../Services/api';

export const loadAccountSettings = () => async (dispatch) => {
	try {
		dispatch(handleAcountSettingsLoading());
		const res = await api.get('/api/account/settings');
		const data = await res.json();
		dispatch(handleAccountSettingsLoaded(data));
	} catch (e) {
		dispatch(handleAccountloadingError());
	}
};

export const handleLoadSelectedUser = (event, user) => async (dispatch) => {
	try {
		dispatch(handleAcountSettingsLoading());
		const res = await api.get(`/api/account/settingsForUser?userId=${user.id}`);
		const data = await res.json();
		dispatch(handleAccountSettingsLoadedForUser(data));
	} catch (e) {
		dispatch(handleAccountloadingError());
	}
};

export const ProcessLoginForUser = () => async (dispatch) => {
	try {
		const res = await api.get('/api/login/ProcessLogin');
		if (res.status === 200) {
			var data = await res.json();
			dispatch(loginDataLoaded(data));
		}
	} catch (err) {
		console.log(err);
	}
};

export const handleSaveAcknowledged = () => {
	return {
		type: ACCOUNT_SAVE_ACK,
	};
};

export const saveAccountSettingsForUser = (values, files) => async (dispatch) => {
	try {
		dispatch(handleDarkMode(values.darkMode));
		dispatch(handleAccountSaving());
		var data = new FormData();
		data.append('AccountSettings', JSON.stringify(values));
		data.append('File', files[0]);

		const res = await api.postWithFile(`/api/account/settingsForUser`, data);
		const result = await res.json();
		dispatch(handleUserAccountSaved(result));
	} catch (e) {
		dispatch(handleAccountSavingError());
	}
}

export const saveAccountSettings = (values, files) => async (dispatch) => {
	try {
		dispatch(handleDarkMode(values.darkMode));
		dispatch(handleAccountSaving());
		var data = new FormData();
		data.append('AccountSettings', JSON.stringify(values));
		data.append('File', files[0]);

		const res = await api.postWithFile('/api/account/settings', data);
		const result = await res.json();
		dispatch(handleAccountSaved(result));
	} catch (e) {
		dispatch(handleAccountSavingError());
	}
};

export const handleDarkMode = (payload) => {
	return {
		type: ACCOUNT_DARK_MODE_TOGGLE,
		payload: payload,
	};
};

export const loginDataLoaded = (payload) => {
	return {
		type: ACCOUNT_PROFILE_LOADED,
		payload: payload,
	};
};

export const handleAccountloadingError = () => {
	return {
		type: ACCOUNT_LOADING_ERROR,
	};
};

export const handleAccountSavingError = () => {
	return {
		type: ACCOUNT_SAVING_ERROR,
	};
};

export const handleAccountSaving = () => {
	return {
		type: ACCOUNT_SAVING,
	};
};

export const handleUserAccountSaved = payload => {
	return {
		type: USER_ACCOUNT_SAVED,
		payload: payload
	};
}

export const handleAccountSaved = (payload) => {
	return {
		type: ACCOUNT_SAVED,
		payload: payload,
	};
};

export const handleAcountSettingsLoading = () => {
	return {
		type: ACCOUNT_LOADING,
	};
};

export const handleAccountSettingsLoaded = (payload) => {
	return {
		type: ACCOUNT_LOADED,
		payload: payload,
	};
};

export const handleAccountSettingsLoadedForUser = (payload) => {
	return {
		type: USER_ACCOUNT_LOADED,
		payload: payload
	};
};
