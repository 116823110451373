import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton, Link, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import LogoMain from '../images/osit-logo-large.png';
import { authProvider } from '../Services/authProvider';
import { AzureAD, LoginType, AuthenticationState } from 'react-aad-msal';
import { store } from '../store/configureStore';

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: '100%',
	},
	grid: {
		height: '100%',
	},
	quoteContainer: {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	quote: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		opacity: '50%',
	},
	quoteInner: {
		textAlign: 'center',
		flexBasis: '600px',
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300,
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white,
	},
	bio: {
		color: theme.palette.white,
	},
	contentContainer: {},
	content: {
		height: 'calc(100vh - 64px)',
		display: 'flex',
		flexDirection: 'column',
		backgroundImage: `url(${LogoMain})`,
		backgroundSize: '515px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'calc(100% + 50px) -70px',
		[theme.breakpoints.down('md')]: {
			backgroundSize: '325px',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'calc(100% + 20px) -50px',
		},
		[theme.breakpoints.down('sm')]: {
			backgroundSize: '225px',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'calc(100% + 20px) -48px',
		},
	},
	contentHeader: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	logoImage: {
		marginLeft: theme.spacing(4),
	},
	contentBody: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 200,
		flexBasis: 700,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	title: {
		marginTop: theme.spacing(3),
	},
	socialButtons: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	socialIcon: {
		marginRight: theme.spacing(1),
	},
	sugestion: {
		marginTop: theme.spacing(2),
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	signInButton: {
		margin: theme.spacing(2, 0),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});

class Login extends React.Component {
	constructor(props) {
		super(props);

		// Change the login type to execute in a Redirect (as some browsers don't like pop-ups!)
		const options = authProvider.getProviderOptions();
		options.loginType = LoginType.Redirect;
		authProvider.setProviderOptions(options);
	}
	componentDidMount() {}
	render() {
		let { classes } = this.props;
		return (
			<AzureAD provider={authProvider} reduxStore={store}>
				{({ login, authenticationState }) => {
					const isInProgress = authenticationState === AuthenticationState.InProgress;
					return (
						<div className={classes.root}>
							<Backdrop className={classes.backdrop} open={isInProgress}>
								<CircularProgress color='inherit' />
							</Backdrop>
							<Grid className={classes.grid} container>
								<Grid className={classes.quoteContainer} item lg={5}>
									<div className={classes.quote}></div>
								</Grid>
								<Grid className={classes.content} item lg={7} xs={12}>
									<div className={classes.content}>
										<div className={classes.contentHeader}>
											<IconButton>
												<ArrowBackIcon />
											</IconButton>
										</div>
										<div className={classes.contentBody}>
											<form className={classes.form}>
												<Typography color='textPrimary' className={classes.title} variant='h3'>
													OSIT Customer Support
												</Typography>
												<Typography color='textSecondary' className={classes.title} variant='h4'>
													Sign in
												</Typography>
												<Grid className={classes.socialButtons} container spacing={2}>
													<Grid item>
														<Button onClick={login} color='primary' variant='contained' disabled={isInProgress}>
															Log In
														</Button>
													</Grid>
													<Grid item></Grid>
												</Grid>
												<Typography color='textSecondary' variant='body2'>
													Having issues? <a href='mailto:sd@osit.com'>Click Here</a>
												</Typography>
											</form>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					);
				}}
			</AzureAD>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Login);
