import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import chatReducer from "./chatReducer";
import authReducer from "./authReducer";
import newsReducer from "./newsReducer";
import notificationsReducer from "./notificationsReducer";
import accountReducer from "./accountReducer";
import clientOverrideReducer from "./clientOverrideReducer";
import dashboardReducer from "./dashboardReducer";
import approvalsReducer from "./approvalsReducer";
import bannerReducer from "./bannerReducer";
import clientConfigsReducer from "./clientConfigsReducer";

let rootReducer = (history) =>
  combineReducers({
    chat: chatReducer,
    auth: authReducer,
    news: newsReducer,
    notifications: notificationsReducer,
    clientconfigs: clientConfigsReducer,
    account: accountReducer,
    override: clientOverrideReducer,
    dashboard: dashboardReducer,
    approvals: approvalsReducer,
    banner: bannerReducer,
    router: connectRouter(history),
  });

export default rootReducer;
