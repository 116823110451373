import React, { Component } from "react";
import { connect } from "react-redux";
import { handleRemoveNotification, setVisibility } from "../../actions/chatActions";

// logic for handling browser visibility
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

class ShowNotification extends Component {
  componentDidMount() {
    console.log("in show notification cdm");
    // event listener for visibility change events
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    this.props.setVisibility("visible");
    // notification permission on mount
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }

  handleVisibilityChange = () => {
    if (document[hidden]) {
      this.props.setVisibility("hidden");
    } else {
      this.props.setVisibility("visible");
    }
  };

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  componentDidUpdate(prevProps, prevState) {
    // show new notifications if they exist when the browserNotifications prop updates
    // which comes from redux store.
    if (prevProps.browserNotifications !== this.props.browserNotifications) {
      this.props.browserNotifications &&
        this.props.browserNotifications.map((notify) => {
          if (!notify.sent) {
            let options = {
              body: notify.body,
              icon: "/osit-logo-128x128.png",
            };

            if (Notification.permission === "granted") {
              let notification = new Notification(notify.title, options);

              notification.onclick = (e) => {
                window.focus();
              };

              this.props.handleRemoveNotification(notify.id);
            }
          }

          return null;
        });
    }
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  const { chat } = state;
  return {
    browserNotifications: chat.browserNotifications,
  };
};

const mapDispatchToProps = {
  handleRemoveNotification,
  setVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowNotification);
