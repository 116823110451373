import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: "https://login.microsoftonline.com/ositau.onmicrosoft.com",
            clientId: "27acb9c5-bf94-4089-b5ee-77b0c20353b8",
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: false,
        },
        // Enable logging of MSAL events for easier troubleshooting.
        // This should be disabled in production builds.
        system: {
            logger: new Logger(
                (logLevel, message, containsPii) => {
                    console.log("[MSAL]", message);
                },
                {
                    level: LogLevel.Warning,
                    piiLoggingEnabled: false,
                }
            ),
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        },
    },
    {
        scopes: ["openid", "User.Read", "api://50997b26-4b01-41f5-a0a1-63ea345c92e8/access_as_user"],
    },
    {
        loginType: LoginType.Redirect,
        // When a token is refreshed it will be done by loading a page in an iframe.
        // Rather than reloading the same page, we can point to an empty html file which will prevent
        // site resources from being loaded twice.
        tokenRefreshUri: window.location.origin + "/auth.html",
    }
);

export function checkRole(role, account) {
    let roles = account.idToken.roles;
    try {
        if (roles.indexOf(role) > -1) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}