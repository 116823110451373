import React from 'react';
import { connect } from 'react-redux';
import { fade, withStyles } from '@material-ui/core/styles';
import { Grid, Snackbar } from '@material-ui/core';
import styled from 'styled-components';
import MuiAlert from '@material-ui/lab/Alert';
import { handleTicketCreatedAcknowledged } from "../../actions/chatActions";
import ChatWindowDetail from './ChatWindowDetail';
import ChatList from './ChatList';
import NoChatWindowSelected from './NoChatWindowSelected';
import NewChatDialogWindow from './NewChatDialogWindow';
import NewTicketDialogWindow from './NewTicketDialogWindow';


function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	gridRoot: {
		minHeight: 'inherit',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
	},
	chatSideBar: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
		borderRight: theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid #333333',
	},
	chatRoot: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
	},
	chatToolbar: {
		borderBottom: theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid #333333',
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 120,
			'&:focus': {
				width: 200,
			},
		},
	},
	userStatus: {
		marginLeft: '30px',
		verticalAlign: 'middle',
		color: 'darkgreen',
	},
	snackBar: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
});

const ChatContainer = styled.div`
	min-height: calc(100vh - 120px);
	overflow: hidden;
`;

class Chat extends React.Component {

	handleCloseModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.handleTicketCreatedAcknowledged();
	};
	
	render() {
		let { classes, conversationDetails, showNewChat, showNewTicket, ticketCreated, loading } = this.props;

		// needed to handle display bug when loading
		let loadingStyle = {
			marginTop: '0px',
		};
		if (loading) {
			loadingStyle = {
				marginTop: '-12px',
			};
		}
		return (
			<ChatContainer>
				<NewChatDialogWindow show={showNewChat} />
				<NewTicketDialogWindow show={showNewTicket} />
				<Grid container className={classes.gridRoot} style={loadingStyle}>
					<Grid item xs={4} sm={3} className={classes.gridRoot}>
						<ChatList />
					</Grid>
					<Grid item xs={8} sm={9} className={classes.gridRoot} display='flex' flexDirection='Column'>
						{conversationDetails ? <ChatWindowDetail /> : <NoChatWindowSelected />}
					</Grid>
				</Grid>
				<div className={classes.snackBar}>
					{ticketCreated != null && (
						<Snackbar onClose={this.handleCloseModal} open={ticketCreated != null} autoHideDuration={8000}>
							<Alert onClose={this.handleCloseModal} severity='success'>
								Created Ticket #{ticketCreated}
							</Alert>
						</Snackbar>
					)}
				</div>
			</ChatContainer>
		);
	}
}

const mapStateToProps = (state) => {
	const { chat } = state;

	return {
		loading: chat.loading,
		showNewChat: chat.showNewChat,
		showNewTicket: chat.showNewTicket,
		ticketCreated: chat.ticketCreated,
		conversationDetails: chat.conversationDetails,
	};
};

const mapDispatchToProps = {
	handleTicketCreatedAcknowledged
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Chat));
