import React from 'react'
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const SCLogo = styled.div`
	padding: 15px 0;
	width: 30px;
	display: inline-block;
	background-origin: content-box;
	background-size: cover;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsSAAALEgHS3X78AAAA70lEQVR4nO3aQQ6CMBRAQWu8/5Xr1pVpFHigM2sSCC9N+qG3GwAAAAAA7G4UN51zzuK+RxpjLL3b+94PwnsCxASICRATICZATICYADEBYskkfGWrU7xJ+CIEiAkQEyAmQOyxeuE/fMN/tbqL+ZYVEBMgJkBMgJgAMQFiAsQEiAkQW56EVx01QX7qbBO9FRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyA2OY/5X/d1ocOrICYADEBYgLEBIhtvgs629G/s7MCYgLEBIgJEBMgJkBMgJgAMQEAAAAAAACAHT0BpkEUha6wjmAAAAAASUVORK5CYII=);
	background-position: center;
	background-repeat: no-repeat;
`;

const SCLogoInverse = styled.div`
	padding: 15px 0;
	width: 30px;
	display: inline-block;
	background-origin: content-box;
	background-size: cover;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADpSURBVHhe7dkxCoNQFABBk1N6RG+ZNBZpBCHq4nem0fa7CO/pBAAAAAAA53ut10vN8/xZb4e1LMuuZ/ter0QEiAkQEyAmQEyAmAAxAWICxJJN+M72bvE24ZsQICZATICYALHdU9ATvuH/2ppiTEGDESAmQEyAmAAxAWICxASICRA7fBPeuwFW/j2HTXgwAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEPNTfsNV5/AGxASICRATICZA7PApaBSmoIcQICZATICYADEBYgLEBIgJAAAAAAAAAJxmmr7HLih5DogF0QAAAABJRU5ErkJggg==);
	background-position: center;
	background-repeat: no-repeat;
`;

export default function ScButton(props) {
  if (props.params.row.online === 'Yes' && props.params.row.computer !== '') {
    return (
      <IconButton aria-label='Screen Connect' onClick={props.onClick}>
        {props.darkMode ? <SCLogo /> : <SCLogoInverse />}
      </IconButton>
    )
  }
  else {
    return <div></div>
  }
}