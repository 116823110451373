import React from "react";
import { Badge, Hidden, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InputIcon from "@material-ui/icons/Input";
import Brightness1 from "@material-ui/icons/Brightness1";
import Cancel from "@material-ui/icons/Cancel";
import { green, red, orange } from "@material-ui/core/colors";

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
});

const renderLogonStatus = (classes, hubStatus) => {
  switch (hubStatus) {
    case 0:
      return (
        <IconButton key={0} className={classes.button} color="inherit">
          <Badge>
            <Cancel style={{ color: red[800] }} />
          </Badge>
        </IconButton>
      );
    case 1:
      return (
        <IconButton key={1} className={classes.button} color="inherit">
          <Badge>
            <Brightness1 style={{ color: green[800] }} />
          </Badge>
        </IconButton>
      );
    case 2:
      return (
        <IconButton key={2} className={classes.button} color="inherit">
          <Badge badgeContent={1} color="primary">
            <Brightness1 style={{ color: orange[800] }} />
          </Badge>
        </IconButton>
      );
    default:
      return (
        <IconButton key={4} className={classes.button} color="inherit">
          <Badge color="primary">
            <Cancel style={{ color: red[800] }} />
          </Badge>
        </IconButton>
      );
  }
};

const renderNotifications = (classes, totalUnreadMessageCount) => {
  if (totalUnreadMessageCount !== null && totalUnreadMessageCount > 0) {
    return (
      <IconButton className={classes.button} color="inherit">
        <Badge badgeContent={totalUnreadMessageCount} color="error">
          <NotificationsActiveIcon />
        </Badge>
      </IconButton>
    );
  } else {
    return (
      <IconButton className={classes.button} color="inherit">
        <Badge color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    );
  }
};

const AuthenticatedActions = ({ classes, logout, isInProgress, onSidebarOpen, hubStatus, totalUnreadMessageCount }) => {
  return (
    <React.Fragment>
      <Hidden mdDown>
        {renderLogonStatus(classes, hubStatus)}
        {renderNotifications(classes, totalUnreadMessageCount)}
        <IconButton className={classes.button} onClick={logout} disabled={isInProgress} color="inherit">
          <InputIcon />
        </IconButton>
      </Hidden>
      <Hidden lgUp>
        <IconButton color="inherit" onClick={onSidebarOpen}>
          <MenuIcon />
        </IconButton>
      </Hidden>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(AuthenticatedActions);
