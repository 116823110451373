import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import ClientConfigsItemsList from "./ClientConfigsItemsList";
import { Link } from "react-router-dom";
import { loadAllClientConfigSettings } from "../../actions/clientConfigsActions";
import Loading from "../Shared/Loading";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    margin: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  spacing: {
    margin: theme.spacing(4),
    marginLeft: theme.spacing(6),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const ClientConfigsContainer = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

class ClientConfigsSettings extends Component {
  static propTypes = {};

  componentDidMount() {
    this.props.loadAllClientConfigSettings();
  }
  render() {
    let { classes, loading, clientConfigData } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <ClientConfigsContainer>
        <Paper item xs={12} className={classes.root}>
          <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.gridRoot}>
              <div className={classes.spacing}>
                <Typography variant="h5" className={classes.heading}>
                  Manage Client Configs
                </Typography>
                <Typography variant="subtitle1" className={classes.heading}>
                  These are the configs that are visible to clients in the Support App
                </Typography>
                <Button component={Link} to="/ClientConfigsSettings/new" variant="contained" color="primary">
                  Add
                </Button>
                <ClientConfigsItemsList clientConfigData={clientConfigData} />
              </div>
            </Grid>
          </Grid>
        </Paper>
        </ClientConfigsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { clientconfigs } = state;
  return {
    loading: clientconfigs.loading,
    clientConfigData: clientconfigs.configData,
  };
};

const mapDispatchToProps = {
  loadAllClientConfigSettings,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ClientConfigsSettings));
