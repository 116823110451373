import {
  SHOW_NEWS_DIALOG,
  CLOSE_NEWS_DIALOG,
  NEWS_ITEM_CREATED,
  NEWS_ITEMS_LOADED,
  NEWS_ITEMS_LOADING
} from '../actions/types/newsTypes';

const initialState = {
  loading: true,
  showNewNews: false
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NEWS_DIALOG:
      return Object.assign({}, state, {
        showNewNews: true
      });
    case CLOSE_NEWS_DIALOG:
      return Object.assign({}, state, {
        showNewNews: false
      });
    case NEWS_ITEM_CREATED:
      return state;
    case NEWS_ITEMS_LOADED:
      return Object.assign({}, state, {
        loading: false,
        newsItems: action.payload
      });
    case NEWS_ITEMS_LOADING:
      return Object.assign({}, state, {
        loading: true
      });
    default:
      return state;
  }
};

export default newsReducer;
