import {
  NOTIFICATIONS_LOADED,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_SENDING_SUCCESS,
  NOTIFICATIONS_SENDING_ERROR,
  NOTIFICATIONS_MESSAGE_CLOSE,
} from "../actions/types/notificationTypes";

const initialState = {
  loading: false,
  broadcastSent: false,
  broadcastSentError: false,
  broadcastSentCount: null,
  clients: [],
  recipients: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADED:
      return Object.assign({}, state, {
        loading: false,
        clients: action.payload.clientData,
        recipients: action.payload.recipientData,
        online: action.payload.onlineData,
      });
    case NOTIFICATIONS_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });
    case NOTIFICATIONS_SENDING_SUCCESS:
      return Object.assign({}, state, {
        broadcastSentCount: action.payload.count,
        broadcastSent: true,
        loading: false,
      });
    case NOTIFICATIONS_SENDING_ERROR:
      return Object.assign({}, state, {
        broadcastSent: false,
        broadcastSentError: true,
        loading: false,
      });
    case NOTIFICATIONS_MESSAGE_CLOSE:
      return Object.assign({}, state, {
        broadcastSentError: false,
        broadcastSent: false,
        broadcastSentCount: null,
      });
    default:
      return state;
  }
};

export default notificationsReducer;
