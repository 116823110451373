import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { unregister } from "./registerServiceWorker";

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

//registerServiceWorker();
unregister();
