import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ImpulseSpinner } from "react-spinners-kit";

const styles = (theme) => ({
  root: {
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:last-child": {
      paddingBottom: "5px",
    },
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
  },
  typing: {
    paddingTop: "20px",
    minWidth: 150,
    display: "inline-block",
  },
  typingText: {
    paddingTop: "10px",
    fontSize: "12px",
    color: theme.palette.type === "light" ? "#212121" : "rgb(152,152,152)",
  },
});

const TypingIndicator = (props) => {
  let { conversation, classes, handleScrollToBottom } = props;

  useEffect(() => {
    handleScrollToBottom();
  }, []);

  if (conversation != null && conversation.typingIndicator != null) {
    if (conversation.typingIndicator.Typing === true) {
      return (
        <React.Fragment>
          <Grid item xs={1} />
          <Grid item xs={8}>
            <div className={classes.typing}>
              <div className={classes.root}>
                <ImpulseSpinner size={60} backColor="#c7c7c7" frontColor="#06a7e2" />
                <Typography className={classes.typingText}>{conversation.toUser.preferredName} is typing...</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} />
        </React.Fragment>
      );
    }
  }

  return null;
};
export default withStyles(styles)(TypingIndicator);
