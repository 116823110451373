import { CLIENTUSAGE_LOADED, CLIENTUSAGE_LOADING, DASHBOARD_LOADED, DASHBOARD_LOADING } from './types/dashboardTypes';
import api from '../Services/api';

export const dashboardLoading = () => {
	return {
		type: DASHBOARD_LOADING,
	};
};

export const dashboardDataLoaded = (payload) => {
	return {
		type: DASHBOARD_LOADED,
		payload: payload,
	};
};

export const loadDashboardData = () => async (dispatch) => {
	try {
		const online = api.get('/api/stats/online');
		const total = api.get('/api/stats/total');
		const osit = api.get('/api/stats/osit');

		const [onlineRes, totalRes, ositRes] = await Promise.all([online, total, osit]);
		const onlineCount = await onlineRes.json();
		const totalCount = await totalRes.json();
		const ositUsers = await ositRes.json();

		let payload = {
			onlineCount,
			totalCount,
			ositUsers,
		};
		dispatch(dashboardDataLoaded(payload));
	} catch (e) {
		console.log(e);
	}
};

export const clientUsageDataLoaded = (payload) => {
	return {
		type: CLIENTUSAGE_LOADED,
		payload: payload,
	};
};

export const clientUsageLoading = () => {
	return {
		type: CLIENTUSAGE_LOADING,
	};
};

export const loadClientUsageData = () => async (dispatch) => {
	try {
		const clientUsageData = await api.get('/api/stats/clientUsageData');
		const result = await clientUsageData.text();

		dispatch(clientUsageDataLoaded(JSON.parse(result, dateReviver)));
	} catch (e) {
		console.log(e);
	}
};

// I am a JSON.parse() reviver that will parse serialized Date objects back into actual
// Date objects.
// --
// CAUTION: This gets called for every single value in the deserialized structure.
function dateReviver(key, value) {
	if (isSerializedDate(value)) {
		return new Date(value);
	}

	// If it's not a date-string, we want to return the value as-is. If we fail to return
	// a value, it will be omitted from the resultant data structure.
	return value;
}

// I determine if the given value is a string that matches the serialized-date pattern.
function isSerializedDate(value) {
	// Dates are serialized in TZ format, example: '1981-12-20T04:00:14.000000'.
	var datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

	return isString(value) && datePattern.test(value);
}

// I determine if the given value is a String.
function isString(value) {
	return {}.toString.call(value) === '[object String]';
}
