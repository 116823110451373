import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { history, store } from "./store/configureStore";
import { hot } from "react-hot-loader";
import Routes from "./Routes";
import ConfigLoader from "./Services/ConfigLoader";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import ShowNotification from "./components/Shared/ShowNotification";

//https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "325276fa-140e-4609-9518-1626af2e69de",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
appInsights.loadAppInsights();

class App extends React.Component {
  componentDidMount() {
    window.addEventListener("focus", this.onFocus);
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <CssBaseline />
          <ConfigLoader ready={() => <Routes />} />
        </ConnectedRouter>
        <ShowNotification />
      </Provider>
    );
  }
}

export default hot(module)(App);
