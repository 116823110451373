import {
  SHOW_BANNER_DIALOG,
  CLOSE_BANNER_DIALOG,
  BANNERS_LOADED,
  BANNERS_LOADING,
  BANNERS_CLIENTS_LOADED
} from '../actions/types/bannerTypes';

const initialState = {
  loading: true,
  showNewBanner: false,
  banners: [],
  durationOptions: [],
  audienceOptions: [],
  clientOptions: []
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BANNER_DIALOG:
      return Object.assign({}, state, {
        showNewBanner: true
      });
    case CLOSE_BANNER_DIALOG:
      return Object.assign({}, state, {
        showNewBanner: false
      });
    case BANNERS_LOADED:
      return Object.assign({}, state, {
        loading: false,
        banners: action.payload.banners,
        durationOptions: action.payload.durationOptions,
        audienceOptions: action.payload.audienceOptions
      });
    case BANNERS_CLIENTS_LOADED:
      return Object.assign({}, state, {
        clientOptions: action.payload
      });
    case BANNERS_LOADING:
      return Object.assign({}, state, {
        loading: true
      });
    default:
      return state;
  }
};

export default bannerReducer;
