import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Contactless from "@material-ui/icons/Contactless";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Announcement from "@material-ui/icons/Announcement";
import NotificationsOff from "@material-ui/icons/NotificationsOff";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BarChart from "@material-ui/icons/BarChart";
import CallToAction from "@material-ui/icons/CallToAction";
import Home from "@material-ui/icons/Home";

import { checkRole, authProvider } from "../../../../../Services/authProvider";

export function getPages() {
  let isAdmin = checkRole("Admin", authProvider.account);
  let isBroadcaster = checkRole("BroadcastUser", authProvider.account);
  let isNewsManager = checkRole("NewsManager", authProvider.account);
  let isApprover = checkRole("ApproverManager", authProvider.account);

  let pages = [
    {
      title: "Dashboard",
      href: "/home",
      icon: <Home />,
    },
    {
      title: "Chat",
      href: "/chat",
      icon: <QuestionAnswer />,
    },
    {
      title: "Deployment Dashboard",
      href: "/ClientUsage",
      icon: <DashboardIcon />,
    },
    {
      title: "Deployment Overview",
      href: "/dashboard",
      icon: <BarChart />,
    },
    {
      title: "Account",
      href: "/account",
      icon: <AccountBoxIcon />,
    },
  ];
  const newsPage = {
    title: "News Settings",
    href: "/newssettings",
    icon: <Announcement />,
  };
  const notificationPage = {
    title: "Broadcasts",
    href: "/notifications",
    icon: <Contactless />,
  };

  const clientOverridesPage = {
    title: "Client Overrides",
    href: "/overrides",
    icon: <NotificationsOff />,
  };

  const approvalsPage = {
    title: "Client Approval",
    href: "/approvals",
    icon: <CheckCircleOutline />,
    };

  const manageClientConfigsPage = {
    title: "Manage Client Configs",
    href: "/clientConfigsSettings",
    icon: <LibraryBooks />,
  };

  const manageAccountsPage = {
    title: "Manage Accounts",
    href: "/manageAccounts",
    icon: <AccountCircle />,
  };

  const bannerPage = {
    title: "Manage Banners",
    href: "/bannerSettings",
    icon: <CallToAction />
  };


  if (isAdmin || isBroadcaster) {
    pages.splice(2, 0, notificationPage);
  }

  if (isNewsManager) {
    pages.splice(3, 0, newsPage);
  }

  if (isApprover) {
    pages.splice(3, 0, approvalsPage);
  }
  if (isAdmin) {
    pages.splice(3, 0, newsPage);
    pages.splice(4, 0, approvalsPage);
    pages.splice(5, 0, clientOverridesPage);
    pages.splice(6, 0, manageClientConfigsPage);
    pages.splice(7, 0, manageAccountsPage);
    pages.splice(8, 0, bannerPage);
  }

  return pages;
}
