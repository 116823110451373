import {
  SHOW_NEWS_DIALOG,
  CLOSE_NEWS_DIALOG,
  NEWS_ITEMS_LOADED,
  NEWS_ITEMS_LOADING,
  NEWS_ITEM_CREATED,
} from "./types/newsTypes";
import api from "../Services/api";
import { push } from "connected-react-router";

export const handleClickNewNews = () => {
  return {
    type: SHOW_NEWS_DIALOG,
  };
};

export const handleCloseNewNews = () => {
  return {
    type: CLOSE_NEWS_DIALOG,
  };
};

export const newsItemsLoading = () => {
  return {
    type: NEWS_ITEMS_LOADING,
  };
};

export const newsItemsLoaded = (payload) => {
  return {
    type: NEWS_ITEMS_LOADED,
    payload: payload,
  };
};

export const loadNewsItems = () => async (dispatch) => {
  try {
    dispatch(newsItemsLoading());
    const res = await api.get("/api/newsadmin/news");
    const data = await res.json();
    dispatch(newsItemsLoaded(data));
  } catch (e) {
    console.log(e);
  }
};

export const loadEditNewsItem = (id) => async (dispatch) => {
  dispatch(push(`/Newssettings/Edit/${id}`));
};

export const newsItemCreated = (payload) => {
  return {
    type: NEWS_ITEM_CREATED,
    payload: payload,
  };
};

export const deleteNewsItem = (id) => async (dispatch) => {
  try {
    dispatch(newsItemsLoading());
    var res = await api.deleteCall("/api/newsadmin/delete", id);

    if (res.status === 200) {
      dispatch(loadNewsItems());
    } else {
    }
  } catch (e) {}
};

export const handleEditNews = (id, values, files) => async (dispatch) => {
  try {
    var data = new FormData();
    data.append("EditNewsItem", JSON.stringify(values));
    data.append("File", files[0]);

    const res = await api.postWithFile(`/api/newsadmin/edit/${id}`, data);
    const result = await res.json();

    dispatch(newsItemCreated(result));
    dispatch(push("/Newssettings"));
  } catch (e) {
    console.log(e);
    //dispatch(loadingError());
  }
};

export const handleCreateNewNews = (values, files) => async (dispatch) => {
  try {
    var data = new FormData();
    data.append("CreateNewsItem", JSON.stringify(values));
    data.append("File", files[0]);

    const res = await api.postWithFile("/api/newsadmin/create", data);
    const result = await res.json();

    dispatch(newsItemCreated(result));
    dispatch(push("/Newssettings"));
  } catch (e) {
    console.log(e);
    //dispatch(loadingError());
  }
};
