import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Loading from "../Shared/Loading";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { loadCompanyApprovalDetailsPage } from "../../actions/approvalsAction";
import { Button } from "@material-ui/core";
import EnhancedTableHead from './EnhancedTableHead';

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  table: {},
  container: {
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
});

    
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class ApprovalsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 10,
      page: 0,
      selectedItem: null,
      orderBy: 'asc',
      order: 'company'
    };
  }

  render() {
    let { clients, classes, loading, loadCompanyApprovalDetailsPage } = this.props;
    let { rowsPerPage, page, orderBy, order } = this.state;

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    };

    const handleDetailsClick = (e, id) => {
      e.stopPropagation();
      loadCompanyApprovalDetailsPage(id);
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      this.setState({ 
        order: isAsc ? "desc" : "asc",
        orderBy: property
      });
    };

    if (loading) {
      return <Loading />;
    } else {
      return (
        <div>
          <React.Fragment>
            <TableContainer className={classes.container} component={Paper}>
              <Table className={classes.table} aria-label="Companies">
                <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                <TableBody>
                  {clients &&
                    stableSort(clients, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.approvalRequired === true ? "True" : "False"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.team}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Button
                            onClick={(e) => handleDetailsClick(e, row.id)}
                            className={classes.menuButton}
                            size="small"
                            variant="outlined"
                            color="primary">
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={clients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </React.Fragment>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { approvals } = state;
  return {
    loading: approvals.loading,
  };
};

const mapDispatchToProps = { loadCompanyApprovalDetailsPage };

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ApprovalsList));
