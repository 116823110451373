import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Grid, Dialog, DialogContent, DialogTitle, Button, Typography, TextField as MuiTextField, CircularProgress } from "@material-ui/core";
import { handleCloseNewTicket, handleCreateNewTicket, handleLoadNewTicketData } from "../../actions/chatActions";
import { withStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { Link } from "react-router-dom";
import { Autocomplete } from "formik-material-ui-lab";

import Loading from "../Shared/Loading";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  mainDialog: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    padding: "0px 25px 25px 25px",
  },
  actions: {
    paddingTop: "50px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 0
  },
  select: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
});

const newTicketSchema = Yup.object().shape({
  summary: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

export class NewTicketDialogWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summary: "",
      description: "",
      selectedPriority: null
    }
  }

  handleCloseNewTicket = () => {
    this.props.handleCloseNewTicket();
  };

  render() {
    let { classes, showNewTicket, loadingNewTicket, conversationDetails, priorities } = this.props;
    let { summary, description, selectedPriority } = this.state;
    
    const formikRef = React.createRef();
    const cancelCreate = () => {};

    const onSubmit = (values) => {
      try {
        values.selectedPriority = this.state.selectedPriority;
        this.props.handleCreateNewTicket(values, conversationDetails.toUser.id);
      } catch (apiException) {
        if (apiException.response && apiException.response.data && apiException.response.data.error) {
          formikRef.current.setErrors(apiException.response.data.error);
        } else {
          console.error(apiException); // or some other fallback handling
        }
      }
    };

      return (
        <div>
          <Dialog
            maxWidth="xl"
            open={showNewTicket}
            onEntering={() => this.props.handleLoadNewTicketData()}
            onClose={this.handleCloseNewTicket}
            aria-labelledby="form-dialog-title">
            <div className={classes.mainDialog}>
              <DialogTitle id="form-dialog-title">
                <Typography variant="h6">Create a New Ticket for {conversationDetails && conversationDetails.toUser.preferredName}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleCloseNewTicket}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
              {loadingNewTicket ? (
                <Grid container justify="center" alignItems="center" style={{ minHeight: "400px" }}>
                  <CircularProgress size={96} />
                </Grid>
                ) : (
                <Formik
                  onSubmit={onSubmit}
                  ref={formikRef}
                  initialValues={{ 
                    summary: summary, 
                    description: description,
                  }}
                  validationSchema={newTicketSchema}>
                    {({ isSubmitting, submitForm, touched, errors }) => (
                      <Form>
                        <Field
                          component={TextField}
                          className={classes.textField}
                          fullWidth
                          type="text"
                          name="summary"
                          variant="outlined"
                          label="Summary"
                        />
                        <Field
                          inputProps={{ maxLength: 250 }}
                          component={TextField}
                          className={classes.textField}
                          fullWidth
                          multiline
                          rows="3"
                          maxLength={250}
                          type="text"
                          name="description"
                          variant="outlined"
                          label="Description"
                        />
                        <Field
                          name="Priority"
                          className={classes.select}
                          component={Autocomplete}
                          options={priorities}
                          value={selectedPriority}
                          onChange={(event, newValue) => {
                            this.setState({
                              selectedPriority: newValue
                            });
                          }}
                          filterSelectedOptions
                          getOptionLabel={(option) => option.label}
                          getOptionSelected={(option, value) => option.label === value.label}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              error={touched["selectedPriority"] && !!errors["selectedPriority"]}
                              helperText={touched["selectedPriority"] && errors["selectedPriority"]}
                              label="Priority"
                              variant="outlined"
                            />
                          )}
                        />
                        <br />
                        <Button
                          className={classes.menuButton}
                          component={Link}
                          variant="text"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={this.handleCloseNewTicket}>
                          Cancel
                        </Button>
                        <Button
                          className={classes.menuButton}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}>
                          CREATE
                        </Button>
                      </Form>
                    )}
                  </Formik>
                )}
              </DialogContent>
            </div>
          </Dialog>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  const { chat } = state;

  return {
    loadingNewTicket: chat.loadingNewTicket,
    priorities: chat.priorities,
    showNewTicket: chat.showNewTicket,
    conversationDetails: chat.conversationDetails,
  };
};

const mapDispatchToProps = {
  handleCloseNewTicket,
  handleCreateNewTicket,
  handleLoadNewTicketData
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(NewTicketDialogWindow)
);
