export const APPROVALS_LOADING = 'APPROVALS_LOADING';
export const LOADING_ERROR = 'LOADING_ERROR';
export const APPROVAL_CLIENTS_LOADED = 'APPROVAL_CLIENTS_LOADED';
export const APPROVAL_DETAILS_LOADED = 'APPROVAL_DETAILS_LOADED';
export const APPROVAL_STATUS_UPDATED = 'APPROVAL_STATUS_UPDATED';
export const APPROVAL_SERVICES_UPDATED = 'APPROVAL_SERVICES_UPDATED';

export const SAVING_APPROVAL_PRODUCTS = 'SAVING_APPROVAL_PRODUCTS';
export const APPROVAL_PRODUCTS_SAVED = 'APPROVAL_PRODUCTS_SAVED';
export const APPROVER_LOCATION_UPDATED = 'APPROVER_LOCATION_UPDATED';
export const APPROVER_ADDED_TO_LOCATION = 'APPROVER_ADDED_TO_LOCATION';
export const SAVING_APPROVERS = 'SAVING_APPROVERS';
export const APPROVERS_SAVED = 'APPROVERS_SAVED';

export const APPROVER_CUSTOM_NOTE_UPDATE = 'APPROVER_CUSTOM_NOTE_UPDATE';
export const APPROVER_CUSTOM_NOTE_SAVED = 'APPROVER_CUSTOM_NOTE_SAVED';
export const APPROVER_CUSTOM_NOTE_SAVING = 'APPROVER_CUSTOM_NOTE_SAVING';
