import {
	ACCOUNT_LOADED,
	ACCOUNT_LOADING,
	ACCOUNT_SAVING_ERROR,
	ACCOUNT_LOADING_ERROR,
	ACCOUNT_SAVED,
	ACCOUNT_SAVING,
	ACCOUNT_PROFILE_LOADED,
	ACCOUNT_SAVE_ACK,
	ACCOUNT_DARK_MODE_TOGGLE,
	USER_ACCOUNT_LOADED,
	USER_ACCOUNT_SAVED
} from '../actions/types/accountTypes';
import produce from 'immer';

const initialState = {
	loading: true,
	errorLoading: null,
	saving: null,
	errorSaving: null,
	settings: null,
	profile: null,
	loadedUserSettings: null,
	theme: {
		palette: {
			type: 'light',
		},
	},
};

const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACCOUNT_PROFILE_LOADED:
			return produce(state, (draftState) => {
				if (action.payload.darkMode) {
					draftState.theme.palette.type = 'dark';
				} else {
					draftState.theme.palette.type = 'light';
				}
				draftState.profile = action.payload;
			});
		case ACCOUNT_LOADED:
			return produce(state, (draftState) => {
				if (action.payload.darkMode) {
					draftState.theme.palette.type = 'dark';
				} else {
					draftState.theme.palette.type = 'light';
				}
				draftState.loading = false;
				draftState.errorLoading = null;
				draftState.success = false;
				draftState.settings = action.payload;
			});
		case USER_ACCOUNT_LOADED:
			return produce(state, (draftState) => {
				draftState.loading = false;
				draftState.errorLoading = null;
				draftState.success = false;
				draftState.loadedUserSettings = action.payload;
			});
		case ACCOUNT_LOADING:
			return Object.assign({}, state, {
				loading: true,
			});
		case ACCOUNT_LOADING_ERROR:
			return Object.assign({}, state, {
				loading: false,
				errorLoading: true,
			});
		case ACCOUNT_SAVING:
			return Object.assign({}, state, {
				saving: true,
			});
		case ACCOUNT_DARK_MODE_TOGGLE:
			return produce(state, (draftState) => {
				if (action.payload === true) {
					draftState.theme.palette.type = 'dark';
				} else {
					draftState.theme.palette.type = 'light';
				}
			});
		case USER_ACCOUNT_SAVED:
			return produce(state, (draftState) => {
				draftState.success = true;
				draftState.saving = false;
				draftState.loadedUserSettings = action.payload;
			});
		case ACCOUNT_SAVED:
			return produce(state, (draftState) => {
				draftState.success = true;
				draftState.saving = false;
				draftState.settings = action.payload;
				if (action.payload.profileImage && action.payload.profileImage.image) {
					draftState.profile.profileImage = action.payload.profileImage.image;
				}
				if (action.payload.profileImageAvatar && action.payload.profileImageAvatar.image) {
					draftState.profile.profileImageAvatar = action.payload.profileImageAvatar.image;
				}
			});
		case ACCOUNT_SAVE_ACK:
			return Object.assign({}, state, {
				success: false,
			});
		case ACCOUNT_SAVING_ERROR:
			return Object.assign({}, state, {
				success: false,
				saving: false,
				errorSaving: true,
			});
		default:
			return state;
	}
};

export default accountReducer;
