import {
  LOADING_USERS,
  LOADING_ERROR,
  LOADING_CHAT_DETAILS,
  CHAT_USERS_LOADED,
  CHAT_DETAILS_LOADED,
  SENDING_NEW_MESSAGE,
  ERROR_SENDING_MESSAGE,
  NEW_MESSAGE_SENT,
  SHOW_NEW_CHAT,
  HIDE_NEW_CHAT,
  DESKTOP_NOTIFICATION_SENT,
  VISIBILITY_CHANGED,
  ADDITIONAL_MESSAGES_ERROR,
  ADDITIONAL_MESSAGES_LOADED,
  LOADING_ADDITION_MESSAGES,
  LOADING_USER_TICKETS,
  LOADED_USER_TICKETS,
  HIDE_NEW_TICKET,
  SHOW_NEW_TICKET,
  LOADING_NEW_TICKET_SETTINGS,
  LOADED_NEW_TICKET_SETTINGS,
  NEW_TICKET_CREATED,
  NEW_TICKET_CREATED_ACK,
  POSTING_TICKET_NOTE,
  TICKET_NOTE_POSTED,
  ERROR_POSTING_TICKET_NOTE,
  CHAT_SNACKBAR_CLOSE,
} from "./types/chatTypes";
import api from "../Services/api";

export const loadingChatUsers = () => {
  return {
    type: LOADING_USERS,
  };
};

export const loadingError = () => {
  return {
    type: LOADING_ERROR,
  };
};

export const chatUsersLoaded = (payload) => {
  return {
    type: CHAT_USERS_LOADED,
    payload: payload,
  };
};

export const loadingChatDetails = () => {
  return {
    type: LOADING_CHAT_DETAILS,
  };
};

export const chatDetailsLoaded = (payload) => {
  return {
    type: CHAT_DETAILS_LOADED,
    payload: payload,
  };
};

export const handleClickNewChat = () => {
  return {
    type: SHOW_NEW_CHAT,
  };
};

export const handleCloseNewChat = () => {
  return {
    type: HIDE_NEW_CHAT,
  };
};

export const setVisibility = (visibility) => {
  return {
    type: VISIBILITY_CHANGED,
    payload: visibility,
  };
};

export const updateTypingIndicator = (chatConversationId, typing) => async (dispatch) => {
  try {
    await api.get(`/api/chat/UserTyping/${chatConversationId}/${typing}`);
  } catch (err) {
    console.log(err);
  }
};

export const handleLoadingUserTickets = () => {
  return {
    type: LOADING_USER_TICKETS,
  };
};

export const userTicketsLoaded = (payload) => {
  return {
    type: LOADED_USER_TICKETS,
    payload: payload,
  };
};

export const handleLoadUserChatTickets = () => async (dispatch) => {
  dispatch(handleLoadingUserTickets());
  try {
    const res = await api.get("/api/chat/userTickets");
    const data = await res.json();

    dispatch(userTicketsLoaded(data));
  } catch (e) {
    console.log(e);
  }
};

export const handleCreateNewChat = (selectedUserId, ticketId, summary) => async (dispatch) => {
  dispatch(handleCloseNewChat());
  dispatch(loadingChatDetails());

  try {
    const res = await api.post("/api/chat/GetOrCreateChat", {
      Id: selectedUserId,
      TicketId: ticketId,
      Summary: summary,
    });
    const data = await res.json();

    // load chat details after creating a new message
    dispatch(LoadChatDetailsForUser());
    dispatch(chatDetailsLoaded(data));
  } catch (e) {
    dispatch(loadingError());
  }
};

export const loadChatDetailWindow = (id) => async (dispatch) => {
  dispatch(loadingChatDetails());

  try {
    const convo = await api.get(`/api/chat/ConversationDetails/${id}`);
    const convoData = await convo.json();

    dispatch(chatDetailsLoaded(convoData));
  } catch {
    dispatch(loadingError());
  }
};

export const LoadChatDetailsForUser = () => async (dispatch) => {
  dispatch(loadingChatUsers());

  try {
    const convos = await api.get("/api/chat/ConversationsForUser");
    const convoData = await convos.json();
    dispatch(chatUsersLoaded(convoData));
  } catch (err) {
    dispatch(loadingError());
  }
};

export const newMessageSendSuccess = (payload) => {
  return {
    type: NEW_MESSAGE_SENT,
    payload: payload,
  };
};

export const sendingNewMessage = () => {
  return {
    type: SENDING_NEW_MESSAGE,
  };
};

export const newMessageSendError = () => {
  return {
    type: ERROR_SENDING_MESSAGE,
  };
};

export const loadingMoreMessages = () => {
  return {
    type: LOADING_ADDITION_MESSAGES,
  };
};

export const addNewMessages = (payload) => {
  return {
    type: ADDITIONAL_MESSAGES_LOADED,
    payload: payload,
  };
};

export const addNewMessagesError = () => {
  return {
    type: ADDITIONAL_MESSAGES_ERROR,
  };
};

export const handleLoadMoreMessages = (conversationId, page) => async (dispatch) => {
  dispatch(loadingMoreMessages());

  let thePage = page == null ? 0 : page;
  try {
    const res = await api.get(`/api/chat/ConversationDetails/${conversationId}/messages/${thePage}`);
    const data = await res.json();
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADDITIONAL_MESSAGES_LOADED,
        payload: data,
      });

      resolve(data.messages.length);
    });
  } catch (err) {
    dispatch(addNewMessagesError());
  }
};

function fileToByteArray(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          for (var i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

export const handleNewImageMessage = (message, conversationId, to, image) => async (dispatch) => {
  dispatch(sendingNewMessage());

  let byteArray = await fileToByteArray(image);

  try {
    const res = await api.post("/api/chat/SendMessage", {
      message: message,
      conversationId: conversationId,
      to: to,
      image: byteArray,
    });
    const data = await res.json();
    dispatch(newMessageSendSuccess(data));
  } catch (err) {
    dispatch(newMessageSendError());
  }
};

export const handleNewMessage = (message, conversationId, to) => async (dispatch) => {
  dispatch(sendingNewMessage());
  try {
    const res = await api.post("/api/chat/SendMessage", {
      message: message,
      conversationId: conversationId,
      to: to,
    });
    const data = await res.json();
    dispatch(newMessageSendSuccess(data));
  } catch (err) {
    dispatch(newMessageSendError());
  }
};

export const postTicketNote = (ticketId, note, isInternalNote = false) => async (dispatch) => {
    dispatch(postingTicketNote());
    try {
        const res = await api.post("/api/chat/PostTicketNote", {
            NoteId: ticketId,
            NoteDetail: note,
            NoteTypes: 2, // Corresponds to the TicketNoteType class enum flag
        });
        const data = await res;
        if (data.ok) {
            dispatch(postedTicketNote());
        }
        else {
            dispatch(errorPostingTicketNote());
        }
    } catch (err) {
        dispatch(errorPostingTicketNote());
    }
};

export const handleRemoveNotification = (id) => {
  return {
    type: DESKTOP_NOTIFICATION_SENT,
    payload: id,
  };
};

export const handleCloseNewTicket = () => {
  return {
    type: HIDE_NEW_TICKET
  };
}

export const handleClickNewTicket = () => {
  return {
    type: SHOW_NEW_TICKET
  };
}

export const loadingNewTicketSettings = () => {
  return {
    type: LOADING_NEW_TICKET_SETTINGS
  };
}

export const loadedNewTicketSettings = (payload) => {
  return {
    type: LOADED_NEW_TICKET_SETTINGS,
    payload: payload
  };
}

export const createdNewTicket = (payload) => {
  return {
    type: NEW_TICKET_CREATED,
    payload: payload
  };
}

export const handleTicketCreatedAcknowledged = () => {
  return {
    type: NEW_TICKET_CREATED_ACK
  };
}

export const postingTicketNote = () => {
    return {
        type: POSTING_TICKET_NOTE,
    };
};

export const postedTicketNote = () => {
    return {
        type: TICKET_NOTE_POSTED,
    };
}

export const handleCloseSnackbar = () => {
    return {
        type: CHAT_SNACKBAR_CLOSE,
    };
};

export const errorPostingTicketNote = () => {
    return {
        type: ERROR_POSTING_TICKET_NOTE
    };
}

export const handleCreateNewTicket = (payload, userId) => async (dispatch) => {
  dispatch(loadingNewTicketSettings());
  try {
    const res = await api.post("/api/ticket/CreateInternal", {
      forUserId : userId,
      summary: payload.summary,
      description: payload.description,
      selectedPriority: payload.selectedPriority
    });
    const data = await res.json();
    dispatch(handleCloseNewTicket());
    dispatch(createdNewTicket(data));
  } catch (err) {
    dispatch(newMessageSendError());
  }
}

export const handleLoadNewTicketData = () => async (dispatch) => {
  dispatch(loadingNewTicketSettings());
  try {
    const res = await api.get("/api/chat/TicketPriorities");
    const data = await res.json();
    dispatch(loadedNewTicketSettings(data));
  } catch (err) {
    dispatch(newMessageSendError());
  }
}