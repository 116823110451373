import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Paper, List, Typography, ListItem, ListItemText, Button, Tooltip } from "@material-ui/core";
import Loading from "../Shared/Loading";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import OverrideListItem from "./OverrideListItem";
import Contactless from "@material-ui/icons/Contactless";
import NotificationsPaused from "@material-ui/icons/NotificationsPaused";
import PermDeviceInformation from "@material-ui/icons/PermDeviceInformation";
import NightsStay from "@material-ui/icons/NightsStay"
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import {
  loadClientOverrideSettings,
  handleSave,
  updateBroadcast,
  updateNotifications,
  updateTracking,
  updateAfterHoursWarning,
  updateQuotesToManagement,
} from "../../actions/overrideActions";
import { CSVLink } from "react-csv";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    margin: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  spacing: {
    margin: theme.spacing(4),
    marginLeft: theme.spacing(6),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerItem: {
    margin: theme.spacing(1),
  },
});

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

const StyledList = styled(List)`
  height: 100%;
  overflow: "scroll";
`;

export class ClientOverrides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      clientExportList: []
    };
  }

  componentDidMount() {
    this.props.loadClientOverrideSettings()
      .then(() => {
        this.createClientExportData()
      });
  }

  createClientExportData = () => {
    let clientList = this.props.clients.map((r) => { return { ConnectWiseCompanyId: r.connectWiseCompanyId, ConnectWiseCompanyName : r.connectWiseCompanyName}});

    this.setState({
      clientExportList : clientList
    });

  }
  render() {
    let { classes, loading, clients, handleSave, updateBroadcast, updateNotifications, updateTracking, updateAfterHoursWarning, updateQuotesToManagement } = this.props;

    if (loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <Paper item xs={12} className={classes.root}>
            <Grid className={classes.root} container>
              <Grid item sm={12} lg={8} className={classes.gridRoot}>
                <div className={classes.spacing}>
                  <Typography variant="h5" className={classes.heading}>
                    Client Overrides
                  </Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      <CSVLink data={this.state.clientExportList} filename="Support App Client List">Download Client List</CSVLink>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={9} sm={10}></Grid>
                    <Grid item xs={3} sm={2}></Grid>
                  </Grid>
                  <StyledList>
                    <ListItem divider={true}>
                      <ListItemText />
                      <Tooltip title="Client Broadcasts" placement="top" arrow>
                        <Contactless color="primary" className={classes.headerItem} />
                      </Tooltip>
                      <Tooltip title="Client Notifications" placement="top" arrow>
                        <NotificationsPaused color="primary" className={classes.headerItem} />
                      </Tooltip>
                      <Tooltip title="Screenshot Tracking" placement="top" arrow>
                        <PermDeviceInformation color="primary" className={classes.headerItem} />
                      </Tooltip>
                      <Tooltip title="After-Hours Warning" placement="top" arrow>
                        <NightsStay color="primary" className={classes.headerItem} />
                      </Tooltip>
                      <Tooltip title="Quotes to Management Board (instead of Sales)" placement="top" arrow>
                        <AssignmentInd color="primary" className={classes.headerItem} />
                      </Tooltip>
                      <Button color="primary" disabled />
                    </ListItem>
                    {clients &&
                      clients.map((client, idx) => {
                        return (
                          <OverrideListItem
                                key={`TodoItem.${idx}`}
                                client={client}
                                divider={idx !== clients.length - 1}
                                handleSave={handleSave}
                                updateBroadcast={updateBroadcast}
                                updateNotifications={updateNotifications}
                                updateTracking={updateTracking}
                                updateAfterHoursWarning={updateAfterHoursWarning}
                                updateQuotesToManagement={updateQuotesToManagement}
                          />
                        );
                      })}
                  </StyledList>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { override } = state;
  return {
    clients: override.clients,
    loading: override.loading,
  };
};

const mapDispatchToProps = {
  loadClientOverrideSettings,
  handleSave,
  updateBroadcast,
  updateNotifications,
  updateTracking,
  updateAfterHoursWarning,
  updateQuotesToManagement,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ClientOverrides));
