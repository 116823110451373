export const CLIENTS_LOADING = "CLIENTS_LOADING";
export const CLIENTS_LOADED = "CLIENTS_LOADED";
export const CLIENTS_LOADED_ERROR = "CLIENTS_LOADED_ERROR";
export const CLIENT_UPDATED = "CLIENT_UPDATED";
export const CLIENT_UPDATED_ERROR = "CLIENT_UPDATED_ERROR";
export const UPDATE_TRACKING = "UPDATE_TRACKING";
export const UPDATE_AFTER_HOURS_WARNING = "UPDATE_AFTER_HOURS_WARNING";
export const UPDATE_QUOTES_TO_MANAGEMENT = "UPDATE_QUOTES_TO_MANAGEMENT"
export const UPDATE_BROADCAST = "UPDATE_BROADCAST";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const SAVING_OVERRIDE = "SAVING_OVERRIDE";
