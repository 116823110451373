import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Grid, Typography, Button, LinearProgress, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";
import { DropzoneDialog } from "material-ui-dropzone";
import { handleCreateNewNews, handleEditNews, loadNewsItems } from "../../actions/newsActions";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import "./dropZoneStyle.css";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
    padding: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(2),
  },
});

const newsItemSchema = Yup.object().shape({
  summary: Yup.string().min(5, "Too Short").max(55, "Too Long").required("Required"),
  url: Yup.string().url("Invalid Url").required(),
  description: Yup.string().min(10, "Too Short").max(120, "Too Long").required("Required"),
});

export class NewsItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      files: [],
      loading: true,
      summary: "",
      description: "",
      url: "",
      isEdit: false,
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id !== null) {
      
      if (this.props.newsItems == null || this.props.newsItems.length < 1) {
				// Data wasn't loaded from previous page (we may have navigated directly here). Load the data first.
				await this.props.loadNewsItems();
			}

      // needs to be == as params.id = "2" and id = 2 for example.
      let editItem = this.props.newsItems.find((n) => n.id == this.props.match.params.id);
      if (editItem) {
        this.setState({
          loading: false,
          summary: editItem.summary,
          description: editItem.description,
          url: editItem.url,
          isEdit: true,
          image: editItem.image,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = (files) => {
    //Saving files to state for further use and closing Modal.
    this.setState({
      files: files,
      open: false,
      fileImage: URL.createObjectURL(files[0]),
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    let { classes } = this.props;
    let { loading, summary, url, description, files, isEdit, image, fileImage } = this.state;
    const formikRef = React.createRef();

    // see https://github.com/jaredpalmer/formik/issues/33
    const onSubmit = (values) => {
      try {
        if (isEdit) {
          this.props.handleEditNews(this.props.match.params.id, values, this.state.files);
        } else {
          this.props.handleCreateNewNews(values, this.state.files);
        }
      } catch (apiException) {
        if (apiException.response && apiException.response.data && apiException.response.data.error) {
          formikRef.current.setErrors(apiException.response.data.error);
        } else {
          console.error(apiException); // or some other fallback handling
        }
      }
    };

    const cancelCreate = () => {};
    if (loading) {
      return <div>loading...</div>;
    } else {
      return (
        <Paper>
          <Grid className={classes.root} container spacing={3}>
            <Formik
              onSubmit={onSubmit}
              cancelCreate={cancelCreate}
              files={files}
              ref={formikRef}
              initialValues={{ summary: summary, description: description, url: url }}
              validationSchema={newsItemSchema}>
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <div>
                    <Typography variant="h5">{isEdit ? "Edit News Item" : "Create a new News Item"}</Typography>
                  </div>
                  <div>
                    <Field
                      inputProps={{ maxLength: 55 }}
                      component={TextField}
                      className={classes.textField}
                      maxLength={55}
                      fullWidth
                      type="text"
                      name="summary"
                      variant="outlined"
                      label="Title"
                    />
                  </div>
                  <Field
                    inputProps={{ maxLength: 120 }}
                    component={TextField}
                    className={classes.textField}
                    fullWidth
                    multiline
                    rows="3"
                    maxLength={120}
                    type="text"
                    name="description"
                    variant="outlined"
                    label="Description"
                  />
                  <Field
                    component={TextField}
                    className={classes.textField}
                    fullWidth
                    type="text"
                    name="url"
                    variant="outlined"
                    label="Url"
                  />
                  <Typography className={classes.textField}>GIF/Image</Typography>
                  {fileImage && <div><img alt="New News Item Hero" style={{ margin: "10px" }} src={fileImage} height="100"></img></div>}
                  {image && !fileImage && (
                    <div>
                      <img
                        alt="News Item Hero"
                        style={{ margin: "10px" }}
                        src={`data:image/*;base64,${image}`}
                        height="100px"/>
                    </div>
                  )}
                  <Button className={classes.menuButton} color='primary' variant='outlined' onClick={this.handleOpen}>
										{isEdit ? 'Replace Image' : 'Add Image'}
                  </Button>
                  <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave}
                    acceptedFiles={["image/gif", "image/jpeg", "image/png", "image/bmp", "image/tiff"]}
                    showPreviews={true}
                    previewGridProps={{item: {xs: 8}}}
                    showFileNames={true}
                    dropzoneText="Drag and drop an image file here or click here."
                    maxFileSize={5000000}
                    filesLimit={1}
                    onClose={this.handleClose}
                  />
                  <br />
                  <br />
                  <br />
                  {isSubmitting && <LinearProgress />}
                  <br />
                  <Button
                    className={classes.menuButton}
                    component={Link}
                    to="/Newssettings"
                    variant="text"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={cancelCreate}>
                    Cancel
                  </Button>
                  <Button
                    className={classes.menuButton}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}>
                    SAVE
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </Paper>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { news } = state;
  return {
    loading: news.loading,
    newsItems: news.newsItems,
  };
};

const mapDispatchToProps = {
  handleCreateNewNews,
  handleEditNews,
  loadNewsItems,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(NewsItemForm));
