import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Typography, Link } from "@material-ui/core";
import { ReactComponent as StartChatLogo } from "../../images/ositChat.svg";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  root: {
    minHeight: "inherit",
    maxHeight: "250px",
    paddingTop: "10px",
  },
  highlight: {
    minHeight: "calc(100vh - 132px)",
    overflow: "hidden",
  },
  heroAction: {
    padding: theme.spacing(6),
  },
});

const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NoChatWindowSelected = ({ classes }) => {
  return (
    <ChatWindow className={classes.root}>
      <Grid className={classes.highlight} container direction="row" justify="center" alignItems="center">
        <Paper className={classes.heroAction}>
          <StartChatLogo style={{ marginTop: "-75px", marginBottom: "-75px" }} />
          <Typography align="center" variant="h4" gutterBottom>
            Chat with Clients
          </Typography>
          <Typography align="center" variant="body">
            Review the{" "}
                      <Link href="https://ositau.sharepoint.com/:w:/r/_layouts/15/Doc.aspx?sourcedoc={9322079C-C3DA-46B7-9C83-2A11ED2E05D5}&file=OSIT Support App Internal Documentation.docx" target="_blank">
              OSIT support process
            </Link>{" "}
            for chat and support processes.
          </Typography>
        </Paper>
      </Grid>
    </ChatWindow>
  );
};

export default withStyles(styles, { withTheme: true })(NoChatWindowSelected);
