import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  Typography,
  Button,
  LinearProgress,
  Paper,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Snackbar,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Autocomplete } from "formik-material-ui-lab";
import MuiAlert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";
import { loadNotificationSettings, handleNewBroadcast, handleCloseSnackbar } from "../../actions/notificationActions";
import * as Yup from "yup";
import Loading from "../Shared/Loading";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  textField: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  select: {
    margin: theme.spacing(2),
    width: "100%",
  },
  firstSelect: {
    marginBottom: theme.spacing(4),
  },
  spacing: {
    margin: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(4),
  },
});

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

const notificationSchema = Yup.object().shape({
  heading: Yup.string().min(5, "Too Short").max(100, "Too Long").required("Required"),
  description: Yup.string().min(20, "Too Short").max(150, "Too Long").required("Required"),
  broadcastLink: Yup.string().url("Please enter a valid URL").nullable(),
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmOpen: false,
    };
  }

  componentDidMount() {
    this.props.loadNotificationSettings();
  }

  render() {
    let { classes, recipients, clients, loading, broadcastSent, broadcastSentCount, broadcastSentError } = this.props;
    let { confirmOpen } = this.state;
    const formikRef = React.createRef();
    // see https://github.com/jaredpalmer/formik/issues/33
    const onSubmit = async (values) => {
      this.setState({
        confirmOpen: false,
      });
      try {
        await this.props.handleNewBroadcast(values);
      } catch (apiException) {
        if (apiException.response && apiException.response.data && apiException.response.data.error) {
          formikRef.current.setErrors(apiException.response.data.error);
        } else {
          console.error(apiException); // or some other fallback handling
        }
      }
    };

    const handleClose = () => {
      this.setState({
        confirmOpen: false,
      });
    };

    const handleCloseSnack = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      this.props.handleCloseSnackbar();
    };

    const handleShowConfirm = (e) => {
      e.stopPropagation();
      this.setState({
        confirmOpen: true,
      });
    };

    if (loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <Paper item xs={12} className={classes.root}>
            <Grid className={classes.root} container>
              <Grid item xs={8} className={classes.gridRoot}>
                <Formik
                  onSubmit={async (values, { resetForm }) => {
                    await onSubmit(values);
                    resetForm({
                      heading: "",
                      description: "",
                      sendOffline: false,
                      clients: [],
                      recipients: null,
                      broadcastLink: "",
                    });
                  }}
                  ref={formikRef}
                  initialValues={{
                    heading: "",
                    description: "",
                    sendOffline: false,
                    clients: [],
                    recipients: null,
                    broadcastLink: "",
                  }}
                  validationSchema={notificationSchema}>
                  {({ isSubmitting, submitForm, values, touched, errors, setFieldValue }) => (
                    <Form className={classes.form}>
                      <div>
                        <Typography className={classes.header} variant="h5">
                          Broadcast a new notification
                        </Typography>
                      </div>
                      <div className={classes.firstSelect}>
                        <Field
                          name="recipients"
                          component={Autocomplete}
                          options={recipients}
                          className={classes.select}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              error={touched["recipients"] && !!errors["recipients"]}
                              helperText={touched["recipients"] && errors["recipients"]}
                              label="recipients"
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                      <Field
                        name="clients"
                        multiple
                        className={classes.select}
                        component={Autocomplete}
                        options={clients}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            error={touched["clients"] && !!errors["clients"]}
                            helperText={touched["clients"] && errors["clients"]}
                            label="clients"
                            variant="outlined"
                          />
                        )}
                      />
                      <Field
                        inputProps={{ maxLength: 75 }}
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        type="text"
                        name="heading"
                        variant="outlined"
                        label="Heading"
                      />
                      <Field
                        inputProps={{ maxLength: 125 }}
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        multiline
                        rows="2"
                        maxLength={150}
                        type="text"
                        name="description"
                        variant="outlined"
                        label="Description"
                      />
                      <Field
                        component={TextField}
                        className={classes.textField}
                        fullWidth
                        multiline
                        type="text"
                        name="broadcastLink"
                        variant="outlined"
                        label="Broadcast Link"
                      />
                      <FormControlLabel
                        className={classes.spacing}
                        checked={values.sendOffline}
                        onChange={() => setFieldValue("sendOffline", !values.sendOffline)}
                        control={<Checkbox />}
                        label="Include offline Users"
                      />
                      <br />
                      {isSubmitting && <LinearProgress />}
                      <Dialog
                        open={confirmOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Send Broadcast?"}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to send this broadcast?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} disabled={isSubmitting} color="primary" variant="text">
                            Cancel
                          </Button>
                          <Button onClick={submitForm} disabled={isSubmitting} color="primary" variant="contained">
                            SEND
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <br />
                      <Button
                        className={classes.menuButton}
                        variant="contained"
                        color="primary"
                        onClick={handleShowConfirm}>
                        SEND
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>

          {broadcastSent && (
            <Snackbar open={broadcastSent} autoHideDuration={8000} onClose={handleCloseSnack}>
              <Alert severity="success" onClose={handleCloseSnack}>
                Broadcast message sent to {broadcastSentCount} recipients.
              </Alert>
            </Snackbar>
          )}

          {broadcastSentError && (
            <Snackbar open={broadcastSent} autoHideDuration={8000} onClose={handleCloseSnack}>
              <Alert severity="warning" onClose={handleCloseSnack}>
                Sorry, Something went wrong! Please try again shortly.
              </Alert>
            </Snackbar>
          )}
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { notifications } = state;
  return {
    loading: notifications.loading,
    clients: notifications.clients,
    recipients: notifications.recipients,
    broadcastSent: notifications.broadcastSent,
    broadcastSentCount: notifications.broadcastSentCount,
    broadcastSentError: notifications.broadcastSentError,
  };
};

const mapDispatchToProps = {
  loadNotificationSettings,
  handleNewBroadcast,
  handleCloseSnackbar,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Notifications));
