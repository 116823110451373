import React from "react";
import { connect } from "react-redux";
import { withAuthentication } from "react-aad-msal";
import { Typography, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { authProvider } from "../Services/authProvider";
import { store } from "../store/configureStore";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    minHeight: "inherit",
    padding: theme.spacing(4),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
}));

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

function Home(props) {
  const classes = useStyles();

  return (
    <Container>
      <Paper item xs={12} className={classes.root}>
        <Grid className={classes.gridRoot} container>
          <Typography>Hey {props.account && props.account.name}, Welcome back.</Typography>
        </Grid>
      </Paper>
    </Container>
  );
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    account: auth.account,
  };
};

export default withAuthentication(connect(mapStateToProps, null)(Home), { provider: authProvider, reduxStore: store });
