import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Grid, Typography, Button, LinearProgress, Paper, TextField as MuiTextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import { loadConfigTypes, loadAllClientConfigSettings, handleAddConfig, loadConfigQuestions, handleEditConfigItem } from '../../actions/clientConfigsActions';
import { Link } from 'react-router-dom';
import { Autocomplete } from 'formik-material-ui-lab';
import Loading from '../Shared/Loading';

import * as Yup from 'yup';

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	gridRoot: {
		minHeight: 'inherit',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
		padding: theme.spacing(4),
	},
	heading: {
		margin: theme.spacing(0, 0, 4, 0),
	},
	textField: {
		margin: theme.spacing(0, 0, 2, 2),
	},
	select: {
		margin: theme.spacing(0, 0, 2, 2),
		width: '100%',
	},
});

const configFormSchema = Yup.object().shape({
	configType: Yup.object().shape({
		connectWiseId: Yup.number().integer('Please select an item'),
	}),
	userFriendlyName: Yup.string().min(5, 'Too Short').max(45, 'Too Long').nullable(),
	userFriendlyDescription: Yup.string().min(10, 'Too Short').max(100, 'Too Long').nullable(),
});

export class ClientConfigsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			loadingConfigQuestions: false,
			isEdit: false,
			selectedConfigType: null,
			selectedConfigQuestions: [],
			userFriendlyName: null,
			userFriendlyDescription: null,
		};
	}

	async componentDidMount() {
		await this.props.loadConfigTypes(); // await, because if editing, we'll need to match these soon.

		if (this.props.match.params.id !== null) {
			// We will be editing an existing database entry

			if (this.props.clientConfigData == null || this.props.clientConfigData.length < 1) {
				// Data wasn't loaded from previous page (we may have navigated directly here). Load the data first.
				await this.props.loadAllClientConfigSettings();
			}

			// needs to be == as params.id = "2" and id = 2 for example.
			let editItem = this.props.clientConfigData.find((n) => n.id == this.props.match.params.id);
			if (editItem) {
				this.setState({
					selectedConfigType: editItem.configType,
					selectedConfigQuestions: editItem.configTypeQuestions,
					userFriendlyName: editItem.configType.userFriendlyName,
					userFriendlyDescription: editItem.configType.userFriendlyDescription,
					isEdit: true,
				});
				// Pre-load the remaining questions for this config type if any
				this.props.loadConfigQuestions(editItem.configType);
			}
		}
	}

	handleSelectedConfigTypeChanged = (option, value) => {
		this.setState({
			selectedConfigType: value,
			selectedConfigQuestions: [], // Empty any selected questions
		});

		// Try to load the questions for this config
		this.props.loadConfigQuestions(value);
	};

	render() {
		let { loading, classes, configTypes, loadingConfigQuestions, configQuestions } = this.props;
		let { selectedConfigType, selectedConfigQuestions, isEdit, userFriendlyName, userFriendlyDescription } = this.state;

		// see https://github.com/jaredpalmer/formik/issues/33
		const onSubmit = async (values, actions) => {
			try {
				values.configType.userFriendlyName = this.state.userFriendlyName;
				values.configType.userFriendlyDescription = this.state.userFriendlyDescription;

				if (isEdit) {
					values.id = this.props.match.params.id; // sets "id" in the sent JSON manually, as this is not stored in the form
					await this.props.handleEditConfigItem(this.props.match.params.id, values); // need to await to handle any exceptions
				} else {
					await this.props.handleAddConfig(values); // need to await to handle any exceptions
				}
			} catch (apiException) {
				if (!apiException.ok && apiException.statusText) {
					switch (apiException.statusText) {
						case 'Bad Request':
							if (!isEdit) {
								actions.setTouched({ configType: true });
								actions.setFieldError('configType', 'Error: Did you mean to edit instead of creating a new entry?');
							} else {
								actions.setTouched({ configTypeQuestions: true });
								actions.setFieldError('configTypeQuestions', 'Error: Please cancel and try again');
							}
							break;
						default:
							actions.setTouched({ configType: true });
							actions.setFieldError('configType', apiException.statusText);
							break;
					}
				} else {
					console.error(apiException); // or some other fallback handling
				}
			}
		};

		const cancelCreate = () => {};

		if (loading) {
			return <Loading />;
		} else {
			// Filter the config types that we don't want selectable
			// We don't want Managed Server (CW ID 18), Managed Workstation (17), Computer (65), Managed Network Computer (92), Veeam Managed Computer (186)
			if (configTypes != null && configTypes.length > 0) {
				const filteredQuestionIDs = [18, 17, 65, 92, 186];
				filteredQuestionIDs.forEach((id) => {
					var foundIndex = configTypes.findIndex((x) => x.connectWiseId == id);
					if (foundIndex > -1) {
						console.log(configTypes.splice(foundIndex, 1));
					}
				});
			}

			return (
				<Paper>
					<Grid className={classes.root} container spacing={3}>
						<Formik
							onSubmit={onSubmit}
							cancelCreate={cancelCreate}
							initialValues={{
								configType: selectedConfigType,
								configTypeQuestions: selectedConfigQuestions,
								userFriendlyName: userFriendlyName,
								userFriendlyDescription: userFriendlyDescription,
							}}
							enableReinitialize={true} // updates the initial values when the values change without manually calling setFieldValue
							validationSchema={configFormSchema}>
							{({ isSubmitting, submitForm, touched, errors }) => (
								<Form>
									<div>
										<Typography variant='h5'>{isEdit ? 'Edit Config' : 'Add a Client Config'}</Typography>
										<Typography variant='subtitle1' className={classes.heading}>
											{isEdit ? 'This config is visible to clients in the Support App' : 'The config will be visible to clients in the Support App'}
										</Typography>
									</div>
									<Field
										name='configType'
										className={classes.select}
										component={Autocomplete}
										value={selectedConfigType}
										options={configTypes}
										disabled={isEdit} // If editing, don't let the user try to change the type of config (they should go back and delete if needed)
										onChange={(option, value) => this.handleSelectedConfigTypeChanged(option, value)}
										filterSelectedOptions
										getOptionLabel={(option) => option.name}
										getOptionSelected={(option, value) => option.connectWiseId === value.connectWiseId}
										renderInput={(params) => (
											<MuiTextField
												{...params}
												error={touched['configType'] && !!errors['configType']}
												helperText={touched['configType'] && errors['configType']}
												label='Config Type'
												variant='outlined'
											/>
										)}
									/>
									{this.state.selectedConfigType != null && !loadingConfigQuestions && (
										<Field
											name='userFriendlyName'
											className={classes.textField}
											component={TextField}
											fullWidth
											inputProps={{ maxLength: 45 }}
											onChange={(event, newValue) => {
												this.setState({
													userFriendlyName: event.target.value,
												});
											}}
											maxLength={45}
											type='text'
											variant='outlined'
											label='User-Friendly Name'
										/>
									)}
									{this.state.selectedConfigType != null && !loadingConfigQuestions && (
										<Field
											name='userFriendlyDescription'
											className={classes.textField}
											component={TextField}
											fullWidth
											multiline
											inputProps={{ maxLength: 100 }}
											onChange={(event, newValue) => {
												this.setState({
													userFriendlyDescription: event.target.value,
												});
											}}
											maxLength={100}
											type='text'
											variant='outlined'
											label='User-Friendly Description'
										/>
									)}
									{loadingConfigQuestions && <LinearProgress />}
									{this.state.selectedConfigType != null && configQuestions != null && !loadingConfigQuestions && (
										<Field
											multiple
											name='configTypeQuestions'
											className={classes.select}
											component={Autocomplete}
											value={selectedConfigQuestions}
											options={configQuestions}
											onChange={(event, newValue) => {
												this.setState({
													selectedConfigQuestions: newValue,
												});
											}}
											filterSelectedOptions
											getOptionLabel={(option) => option.question}
											getOptionSelected={(option, value) => option.connectWiseId === value.connectWiseId}
											renderInput={(params) => (
												<MuiTextField
													{...params}
													error={touched['configTypeQuestions'] && !!errors['configTypeQuestions']}
													helperText={touched['configTypeQuestions'] && errors['configTypeQuestions']}
													label='Ignored Questions (optional)'
													variant='outlined'
												/>
											)}
										/>
									)}
									<br />
									{isSubmitting && <LinearProgress />}
									<br />
									<Button className={classes.menuButton} component={Link} to='/ClientConfigsSettings' variant='text' color='primary' disabled={isSubmitting} onClick={cancelCreate}>
										Cancel
									</Button>
									<Button className={classes.menuButton} variant='contained' color='primary' disabled={isSubmitting || selectedConfigType == null} onClick={submitForm}>
										SAVE
									</Button>
								</Form>
							)}
						</Formik>
					</Grid>
				</Paper>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const { clientconfigs } = state;
	return {
		loading: clientconfigs.loading,
		configTypes: clientconfigs.configTypes,
		loadingConfigQuestions: clientconfigs.loadingConfigQuestions,
		configQuestions: clientconfigs.configQuestions,
		clientConfigData: clientconfigs.configData,
	};
};

const mapDispatchToProps = {
	handleEditConfigItem,
	loadConfigTypes,
	loadConfigQuestions,
	loadAllClientConfigSettings,
	handleAddConfig,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ClientConfigsForm));
