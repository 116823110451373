import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Grid, Button, CircularProgress } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ChatIcon from "@material-ui/icons/Chat";
import { withStyles } from "@material-ui/core/styles";
import { handleNewMessage, handleNewImageMessage, updateTypingIndicator } from "../../actions/chatActions";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { DropzoneDialog } from "material-ui-dropzone";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
});

export class NewChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      files: [],
    };
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = (files) => {
    let { handleNewImageMessage, conversationDetails } = this.props;
    let { message } = this.state;

    this.setState({
      files: [],
      open: false,
      fileImage: URL.createObjectURL(files[0]),
    });

    handleNewImageMessage(
      message,
      conversationDetails.chatConversationId,
      conversationDetails.toUser.userName,
      files[0]
    );
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleInputChange = (e) => {
    let { updateTypingIndicator, conversationDetails } = this.props;

    if (e.target.value === "" && this.state.message !== "") {
      updateTypingIndicator(conversationDetails.chatConversationId, false);
    } else if (e.target.value !== "" && this.state.message === "") {
      updateTypingIndicator(conversationDetails.chatConversationId, true);
    }

    this.setState({ message: e.target.value });
  };

  handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.handleSendMessage();
    }
  };

  handleSendMessage = () => {
    let { handleNewMessage, conversationDetails } = this.props;
    let { message } = this.state;
    handleNewMessage(message, conversationDetails.chatConversationId, conversationDetails.toUser.userName);
    this.setState({
      message: "",
    });
    this.props.handleScrollNewLocalMsg();
  };

  render() {
    let { classes, sendingMessage } = this.props;
    return (
      <Grid alignItems="center" container spacing={3} className={classes.root}>
        <Grid item xs={1}>
          <ChatIcon className={classes.icon} color="secondary" fontSize="large"></ChatIcon>
        </Grid>
        <Grid item xs={8}>
          <Input
            onKeyDown={(e) => this.handleEnterPress(e)}
            onChange={(e) => this.handleInputChange(e)}
            autoFocus
            color="secondary"
            fullWidth
            placeholder="Type a new Message"
            value={this.state.message}></Input>
        </Grid>
        <Grid item xs={1}>
          <label htmlFor="icon-button-file">
            <IconButton
              onClick={this.handleOpen}
              size="medium"
              color="secondary"
              aria-label="upload picture"
              component="span">
              <PhotoCamera />
            </IconButton>
          </label>
          <DropzoneDialog
            open={this.state.open}
            onSave={this.handleSave}
            acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
            showPreviews={true}
            showFileNames={true}
            dropzoneText="Drag and drop an image file here or click here."
            maxFileSize={5000000}
            filesLimit={1}
            onClose={this.handleClose}
          />
        </Grid>
        <Grid item xs={2}>
          <div className={classes.wrapper}>
            <Button
              onClick={this.handleSendMessage}
              variant="contained"
              color="secondary"
              disabled={sendingMessage || this.state.message === ""}
              className={classes.button}
              endIcon={<SendIcon></SendIcon>}>
              Send
            </Button>
            {sendingMessage && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  const { chat, auth } = state;
  return {
    conversationDetails: chat.conversationDetails,
    userName: auth.account.userName,
    sendingMessage: chat.sendingMessage,
  };
};

const mapDispatchToProps = {
  handleNewMessage,
  handleNewImageMessage,
  updateTypingIndicator,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(NewChat));
