import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import {
  NEW_MESSAGE_RECEIVED,
  TYPING_INDICATOR_UPDATE,
  PRESENCESTATUS_UPDATE,
  HUB_DISCONNECTED,
  HUB_RECONNECTED,
  HUB_CONNECTED,
  ACK_MESSAGE_RECEIVED,
  MESSAGE_ACK_PENDING,
  CHAT_CONVO_UPDATE,
} from "../actions/types/chatTypes";
import { AuthenticationActions } from "react-aad-msal";
import { ProcessLoginForUser } from "../actions/accountActions";
import { LoadChatDetailsForUser } from "../actions/chatActions";
import { authProvider } from "../Services/authProvider";
import config from "../Services/config";

let hubConnect;

const signalRMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type === AuthenticationActions.LoginSuccess) {
    dispatch(ProcessLoginForUser());
    dispatch(LoadChatDetailsForUser());
    const createHubConnection = async () => {
      // Build new Hub Connection,
      const apiScope = {
        scopes: [config.apiScope],
      };
      hubConnect = new HubConnectionBuilder()
        .withUrl(`${config.apiUrl}/hubs/chat`, {
          accessTokenFactory: async () => {
            let result = await authProvider.getAccessToken(apiScope);
            return result.accessToken;
          },
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      hubConnect.onreconnecting((error) => {
        dispatch({
          type: HUB_DISCONNECTED,
          payload: error,
        });
      });

      hubConnect.onreconnected((connectionId) => {
        dispatch({
          type: HUB_RECONNECTED,
          payload: connectionId,
        });
      });

      hubConnect.onclose((err) => {
        console.log(err);
      });
      try {
        await hubConnect.start();

        // hub connected event.
        dispatch({
          type: HUB_CONNECTED,
        });
        // event handlers, you can use these to dispatch actions to update your Redux store
        hubConnect.on("SendChatMessage", (res, notificationGuid) => {
          const message = JSON.parse(res);
          dispatch({
            type: NEW_MESSAGE_RECEIVED,
            payload: {
              data: message,
              id: notificationGuid,
            },
          });
          let currentRoute = getState().router.location.pathname;
          let visibility = getState().chat.visibility;
          let convDetails = getState().chat.conversationDetails;

          if (
            currentRoute.includes("chat") &&
            visibility === "visible" &&
            convDetails.chatConversationId === message.chatConversationId
          ) {
            dispatch({
              type: ACK_MESSAGE_RECEIVED,
              payload: notificationGuid,
            });
          } else {
            dispatch({
              type: MESSAGE_ACK_PENDING,
              payload: {
                id: notificationGuid,
                message: message,
                conversationId: message.chatConversationId,
              },
            });
          }
        });

        hubConnect.on("TypingIndicator", (res) =>
          dispatch({
            type: TYPING_INDICATOR_UPDATE,
            payload: JSON.parse(res),
          })
        );

        hubConnect.on("PresenceStatus", (res) => {
          dispatch({
            type: PRESENCESTATUS_UPDATE,
            payload: JSON.parse(res),
          });
        });

        hubConnect.on("SendNotificationMessage", (res) => {
          console.log(res);
        });

        hubConnect.on("ChatConversationUpdate", (res) => {
          dispatch({
            type: CHAT_CONVO_UPDATE,
            payload: JSON.parse(res),
          });
        });
      } catch (err) {
        alert(err);
        console.log(
          "Error while establishing connection: " +
            {
              err,
            }
        );
      }
    };
    createHubConnection();
  }

  // intercept redux with this middleware to handle the required signalR invoke methods from dispatched actions.
  switch (action.type) {
    case ACK_MESSAGE_RECEIVED:
      hubConnect.invoke("Acknowledge", action.payload).catch((err) => console.log(err));
      return;
    default:
      break;
  }

  return next(action);
};

export default signalRMiddleware;
