import React, { Component } from 'react';
import { Typography, Toolbar, IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import styled from 'styled-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Helmet } from 'react-helmet';
import { fade, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { handleClickNewTicket, postTicketNote, handleCloseSnackbar } from '../../actions/chatActions';
import ChatMessages from './ChatMessages';
import NewChat from './NewChat';
import Loading from '../Shared/Loading';
import Button from '@material-ui/core/Button';
import Create from '@material-ui/icons/Create';
import Description from '@material-ui/icons/Description';

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
		maxHeight: '250px',
		paddingTop: '10px',
	},
	chatToolbar: {
		borderBottom: theme.palette.type === 'light' ? '1px solid #eeeeee' : '1px solid #333333',
	},
	margin: {
		marginRight: 15,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		color: theme.palette.type === 'light' ? '#333333' : '#eeeeee',
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 120,
			'&:focus': {
				width: 200,
			},
		},
	},
	userOnline: {
		marginRight: '20px',
		verticalAlign: 'middle',
		color: 'darkgreen',
	},
	userOffline: {
		marginRight: '20px',
		verticalAlign: 'middle',
		color: 'darkred',
	},
	userAway: {
		marginRight: '20px',
		verticalAlign: 'middle',
		color: 'orange',
	},
});

const ChatWindow = styled.div`
	display: flex;
	flex-direction: column;
`;

const handleStatusType = (status) => {
	switch (status) {
		case 0:
			return 'darkred';
		case 1:
			return 'darkgreen';
		case 2:
			return 'orange';
		default:
			return 'darkred';
	}
};

const UserStatus = styled(FiberManualRecordIcon)`
	margin-right: 20px;
	vertical-align: middle;
	color: ${({ status }) => handleStatusType(status)};
`;

const SCLogo = styled.div`
	padding: 15px 0;
	width: 30px;
	display: inline-block;
	background-origin: content-box;
	background-size: cover;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsSAAALEgHS3X78AAAA70lEQVR4nO3aQQ6CMBRAQWu8/5Xr1pVpFHigM2sSCC9N+qG3GwAAAAAA7G4UN51zzuK+RxpjLL3b+94PwnsCxASICRATICZATICYADEBYskkfGWrU7xJ+CIEiAkQEyAmQOyxeuE/fMN/tbqL+ZYVEBMgJkBMgJgAMQFiAsQEiAkQW56EVx01QX7qbBO9FRATICZATICYADEBYgLEBIgJEBMgJkBMgJgAMQFiAsQEiAkQEyAmQEyA2OY/5X/d1ocOrICYADEBYgLEBIhtvgs629G/s7MCYgLEBIgJEBMgJkBMgJgAMQEAAAAAAACAHT0BpkEUha6wjmAAAAAASUVORK5CYII=);
	background-position: center;
	background-repeat: no-repeat;
`;

const SCLogoInverse = styled.div`
	padding: 15px 0;
	width: 30px;
	display: inline-block;
	background-origin: content-box;
	background-size: cover;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADpSURBVHhe7dkxCoNQFABBk1N6RG+ZNBZpBCHq4nem0fa7CO/pBAAAAAAA53ut10vN8/xZb4e1LMuuZ/ter0QEiAkQEyAmQEyAmAAxAWICxJJN+M72bvE24ZsQICZATICYALHdU9ATvuH/2ppiTEGDESAmQEyAmAAxAWICxASICRA7fBPeuwFW/j2HTXgwAsQEiAkQEyAmQEyAmAAxAWICxASICRATICZATICYADEBYgLEBIgJEPNTfsNV5/AGxASICRATICZA7PApaBSmoIcQICZATICYADEBYgLEBIgJAAAAAAAAAJxmmr7HLih5DogF0QAAAABJRU5ErkJggg==);
	background-position: center;
	background-repeat: no-repeat;
`;

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class ChatWindowDetail extends Component {
	constructor(props) {
		super(props);
		this.messagesRef = React.createRef();
	}
	launchScSession = (launchUrl) => {
		window.open(launchUrl, '_blank');
	};

	// ref into the messages component, scroll to bottom on local message sent.
	handleScrollNewLocalMsg = () => {
		this.messagesRef.current.scrollToBottom(true);
	};

	handleClickChatExport = () => {
		
		// Store a dummy date. If the date changes between messages, it will insert it at the top of the messages
		var dummyDate = new Date("01/01/1970");
		var lastMessageDate = dummyDate;
		function parseMessage(message)
		{
			var messageExport = "";

			var theDateTime = new Date(message.created);
			if (lastMessageDate.getDate() != theDateTime.getDate())
			{
				if (lastMessageDate != dummyDate)
				{
					messageExport += "\n"; // If the date changes in the middle, add an extra space first
				}
				messageExport += theDateTime.toLocaleDateString(undefined, { timeZoneName: 'short' }) + "\n";
			}
			lastMessageDate = theDateTime;

			messageExport += message.fromDisplayFullName ? message.fromDisplayFullName : message.from
			messageExport += "\t";		

			messageExport += theDateTime.toLocaleTimeString() + "\t";

			if (message.imageUrl != null)
			{
				messageExport += "<Image>";
			}

			messageExport += message.message;

			return messageExport;
        }

		// Store the UTC chat started date
		var chatStartedDate = new Date(this.props.conversationDetails.ticketChatStartedDate);

		var theMessages = Array.from(this.props.conversationDetails.chatMessages);
		var exportedConversation = "Exported conversation:\n\n";
		for (let i = 0; i < theMessages.length; i++)
		{
			// We will only export conversation from this ticket's start date
			
			var messageDate = new Date(theMessages[i].created); // Message date in UTC
			
			if (messageDate >= chatStartedDate)
			{
				exportedConversation += (parseMessage(theMessages[i])) + "\n";
			}
		}

		// Download the ticket notes to a file
		//var link = document.createElement('a');
		//link.download = 'Chat_' + this.props.conversation.toUser.preferredName + "_" + Date.now() + '.txt';
		//var blob = new Blob([exportedConversation], { type: 'text/plain' });
		//link.href = window.URL.createObjectURL(blob);
		//link.click();

		// Export data to ticket internal notes
		this.props.postTicketNote(this.props.conversationDetails.ticketId, exportedConversation.toString(), true);
	};

	render() {
		let { classes, loading, conversationDetails, conversation, darkMode, ticketNotePosted, ticketNotePostedError } = this.props;

		const handleCloseSnack = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			this.props.handleCloseSnackbar();
		};

		if (loading) {
			return <Loading />;
		}
		return (
			<ChatWindow className={classes.root}>
				<Helmet>
					<script src='https://screenconnect.officesolutions.com.au/Script.ashx' type='text/javascript' />
				</Helmet>
				<Toolbar className={classes.chatToolbar}>
					<Typography variant='h6' className={classes.title} color='textPrimary'>
						{conversation && <UserStatus status={conversation.fromUserConnectionStatus} />}
						{conversationDetails.scLaunchUrl && (
							<IconButton aria-label='Screen Connect' className={classes.margin} onClick={() => this.launchScSession(conversationDetails.scLaunchUrl)}>
								{darkMode ? <SCLogo /> : <SCLogoInverse />}
							</IconButton>
						)}
						{conversationDetails.toUser.preferredName}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleClickChatExport}
						className={classes.button}
						startIcon={<Description />}>
							Export Chat to Ticket
					</Button>
					<span>&nbsp;&nbsp;</span>
					<Button
							variant="contained"
							color="primary"
							onClick={this.props.handleClickNewTicket}
							className={classes.button}
							startIcon={<Create />}>
								New Ticket
					</Button>
				</Toolbar>
				<ChatMessages ref={this.messagesRef} />
				<NewChat handleScrollNewLocalMsg={this.handleScrollNewLocalMsg} />

				{ticketNotePosted && (
					<Snackbar open={ticketNotePosted} autoHideDuration={8000} onClose={handleCloseSnack}>
						<Alert severity="success" onClose={handleCloseSnack}>
							Chat exported to ticket #{this.props.conversationDetails.ticketId} internal notes.
						</Alert>
					</Snackbar>
				)}

				{ticketNotePostedError && (
					<Snackbar open={ticketNotePostedError} autoHideDuration={8000} onClose={handleCloseSnack}>
						<Alert severity="warning" onClose={handleCloseSnack}>
							Error exporting chat to ticket #{this.props.conversationDetails.ticketId}. Please try again later.
						</Alert>
					</Snackbar>
				)}

			</ChatWindow>
		);
	}
}

const mapStateToProps = (state) => {
	const { chat, account } = state;

	let darkMode = false;
	if (account.theme !== null && account.theme.palette !== null && account.theme.palette.type === 'dark') {
		darkMode = true;
	}
	return {
		conversationDetails: chat.conversationDetails,
		conversation: chat.conversations.find((c) => c.chatConversationId === chat.conversationDetails.chatConversationId),
		loading: chat.loading,
		darkMode: darkMode,
		ticketNotePosted: chat.ticketNotePosted,
		ticketNotePostedError: chat.ticketNotePostedError,
	};
};

const mapDispatchToProps = {
	handleClickNewTicket,
	postTicketNote,
	handleCloseSnackbar
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ChatWindowDetail));
