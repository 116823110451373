import { DASHBOARD_LOADED, DASHBOARD_LOADING, CLIENTUSAGE_LOADING, CLIENTUSAGE_LOADED } from '../actions/types/dashboardTypes';

const initialState = {
	loading: true,
	onlineCount: 0,
	totalCount: 0,
	ositCount: 0,
	ositUsers: [],
	clientUsageData: [],
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD_LOADING:
			return Object.assign({}, state, {
				loading: true,
			});
		case DASHBOARD_LOADED:
			return Object.assign({}, state, {
				loading: false,
				onlineCount: action.payload.onlineCount,
				totalCount: action.payload.totalCount,
				ositCount: action.payload.ositUsers.length,
				ositUsers: action.payload.ositUsers,
			});
		case CLIENTUSAGE_LOADING:
			return Object.assign({}, state, {
				loading: true,
			});
		case CLIENTUSAGE_LOADED:
			return Object.assign({}, state, {
				loading: false,
				clientUsageData: action.payload,
			});
		default:
			return state;
	}
};

export default dashboardReducer;
