import React, { Component } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import { Typography, Grid, Button, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Loading from '../Shared/Loading';
import { loadClientUsageData } from '../../actions/dashboardActions';
import { Helmet } from 'react-helmet';
import { CSVLink } from "react-csv";

import api from '../../Services/api';

import ScButton from './ScButton';

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	menuButton: {
		margin: theme.spacing(2),
	},
	gridRoot: {
		minHeight: 'inherit',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
	},
	textField: {
		margin: theme.spacing(2),
	},
	spacing: {
		margin: theme.spacing(2),
	},
	spacingImg: {
		margin: theme.spacing(4),
	},
	dataGrid: {
		minHeight: 'calc(100vh - 250px)',
		margin: theme.spacing(2)
	},
	heading: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0),
	},
	snackBar: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
});

const Container = styled.div`
	min-height: calc(100vh - 177px);
	overflow: hidden;
`;

const columns = [
	{ field: 'id', hide: true },
	{ field: 'client', headerName: 'Client', flex: 0.7 },
	{ field: 'computer', headerName: 'Computer', flex: 0.7 },
	{ field: 'user', headerName: 'User', flex: 1 },
	{ field: 'version', headerName: 'Version', flex: 0.5 },
	{
		field: 'lastLogin',
		headerName: 'Last Login',
		type: 'date',
		flex: 0.5,
	},
	{
		field: 'initialLogin',
		headerName: 'Sign Up Date',
		type: 'date',
		flex: 0.5,
	},
	{
		field: 'scLaunchQueryUrl',
		flex: 0.3,
		headerName: 'Remote',
		renderCell: (params) => {
			const onClick = () => {
        const gridApi = params.api;
        const fields = gridApi
          .getAllColumns()
          .map((c) => c.field)
					.filter((c) => c !== "__check__" && !!c);
				
				api.get(params.value)
					.then(res => {
						console.log(res);
						if (res.ok) {
							res.text().then(url => {
								window.open(url, '_blank');	
							});
						}
						else {
							alert('Sorry we could not find that computer in ScreenConnect');
						}
					})
					.catch(e => {
						alert('sorry something went wrong');
					});
				// api query here to load from backend the url to launch in a new window
			}
			return <ScButton params={params} onClick={onClick}></ScButton>
		}
	},
	{ field: 'online', headerName: 'Online?', flex: 0.5 },
];

class ClientUsage extends Component {
	componentDidMount() {
		this.props.loadClientUsageData();
	}

	render() {
		let { loading, clientUsageData, classes } = this.props;

		if (loading) {
			return <Loading />;
		} else {
			return (
				<Container>
					<Helmet>
						<script src='https://screenconnect.officesolutions.com.au/Script.ashx' type='text/javascript' />
					</Helmet>
					<Grid container spacing={3} direction='row' alignItems='stretch'>
						<Grid item xs={7}>
							<Typography gutterBottom color='textPrimary' className={classes.heading} variant='h4'>
								Deployment Dashboard
							</Typography>
						</Grid>
						<Grid container item xs={5} style={{ marginTop: '8px' }} spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end" >
							<Grid item xl={7} lg={6} sm={3}>
								<CSVLink style={{ float: 'right' }} data={this.props.clientUsageData} filename="Support App Client Deployments List">
									<Button variant='contained' color='primary'>
										Export
									</Button>
								</CSVLink>
							</Grid>
							<Grid item xl={5} lg={6} sm={9}>
								<Button component={Link} to='/Dashboard' variant='contained' color='primary'>
									Deployment Overview
								</Button>						
							</Grid>	
						</Grid>
					</Grid>
					<Paper square={false} item xs={12} className={classes.root}>
						<Grid className={classes.root} container>
							<Grid item xs={12} className={classes.gridRoot}>
								<div className={classes.dataGrid}>
									<DataGrid pagination pageSize={20} rowsPerPageOptions={[20, 50, 100]} rows={clientUsageData} columns={columns} density="compact" />
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const { dashboard } = state;
	return {
		loading: dashboard.loading,
		clientUsageData: dashboard.clientUsageData,
	};
};

const mapDispatchToProps = {
	loadClientUsageData,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ClientUsage));
