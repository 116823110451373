import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import logo from "../../../../images/osit-logo.png";
import styled from "styled-components";
import { authProvider } from "../../../../Services/authProvider";
import { store } from "../../../../store/configureStore";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import AuthenticatedActions from "./AuthenticatedActions";

const styles = (theme) => ({
  root: {
    boxShadow: "none",
    zIndex: 900,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
});

const Logo = styled.img`
  max-width: 50px;
  margin: 10px;
  padding-right: 10px;
`;

export class Topbar extends Component {
  render() {
    const { classes, className, onSidebarOpen, hubStatus, totalUnreadMessageCount, ...rest } = this.props;

    return (
      <AzureAD provider={authProvider} reduxStore={store}>
        {({ login, logout, authenticationState }) => {
          const isInProgress = authenticationState === AuthenticationState.InProgress;
          const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
          return (
            <AppBar {...rest} className={clsx(classes.root, className)}>
              <Toolbar>
                <RouterLink to="/">
                  <Logo alt="Logo" src={logo} className={classes.img} />
                </RouterLink>
                <Typography variant="h5" className={classes.title}>
                  Office Solutions IT
                </Typography>
                <div className={classes.flexGrow} />
                {isAuthenticated ? (
                  <AuthenticatedActions
                    hubStatus={hubStatus}
                    totalUnreadMessageCount={totalUnreadMessageCount}
                    logout={logout}
                    onSidebarOpen={onSidebarOpen}
                    isInProgress={isInProgress}
                  />
                ) : (
                  <Button color="inherit" onClick={login} disabled={isInProgress}>
                    Login
                  </Button>
                )}
              </Toolbar>
            </AppBar>
          );
        }}
      </AzureAD>
    );
  }
}

const mapStateToProps = (state) => {
  const { chat } = state;
  return {
    hubStatus: chat.hubStatus,
    totalUnreadMessageCount: chat.totalUnreadMessageCount,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(Topbar));
