import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  email: {
    fontSize: "14px",
  },
  company: {
    fontSize: "12px",
    color: "grey",
  },
}));

const Profile = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: props.account && props.account.name,
    avatar: props.profile && props.profile.profileImage,
    bio: props.account && props.account.userName,
    company: props.profile && props.profile.connectWiseCompany,
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={user.name}
        className={classes.avatar}
        component={RouterLink}
        src={`data:image/jpeg;base64,${user.avatar}`}
        to="/account"
      />
      <Typography className={classes.name} variant="subtitle2">
        {user.name}
      </Typography>
      <Typography variant="body2" className={classes.email}>
        {user.bio}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { auth, account } = state;
  return {
    account: auth.account,
    profile: account.profile,
  };
};

export default connect(mapStateToProps, null)(Profile);
