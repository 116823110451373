import React from "react";
import Table from "@material-ui/core/Table";
import Chip from "@material-ui/core/Chip";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { deleteConfig, loadEditConfigItem } from "../../actions/clientConfigsActions";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { red } from "@material-ui/core/colors";

const DeleteButton = withStyles((theme) => ({
  root: {
    color: red[700],
    border: "1px solid",
    borderColor: red[700],
    marginLeft: "10px",
    "&:hover": {
      color: red[900],
      borderColor: red[900],
    },
  },
}))(Button);

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  configTypeQuestionsColumn: {
    maxWidth: theme.spacing(40)
  },
});

class ClientConfigsItemsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage: 5,
      page: 0,
      deleteOpen: false,
      selectedItem: null,
    };
  }

  configTypeQuestionsToChips = (configTypeQuestions) =>
  {
      var questions=[];
      var key = 0; // for key ID of each chip
      configTypeQuestions.forEach ( (question) =>
      {
        // Convert question into a chip
        var chip = <Chip key={key}
        className={this.props.classes.chip}
        size="small"
        label={question.question} />

        questions.push(chip);
        key++;
      });
      
    return questions;
  };

  render() {
    let { clientConfigData, classes } = this.props;
    let { rowsPerPage, selectedItem, page, deleteOpen } = this.state;

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    };

    const handleClose = () => {
      this.setState({
        deleteOpen: false,
      });
    };

    const handleDelete = (e) => {
      e.stopPropagation();
      this.props.deleteConfig(selectedItem.id);
      this.setState({
        deleteOpen: false,
      });
    };

    const handleEditClick = (e, id) => {
      e.stopPropagation();
      this.props.loadEditConfigItem(id);
    };

    const handleClickDelete = (e, id) => {
      e.stopPropagation();
      this.setState({
        deleteOpen: true,
        selectedItem: clientConfigData.find((n) => n.id === id),
      });
    };

    return (
      <div>
        <React.Fragment>
          <TableContainer className={classes.container} component={Paper}>
            <Table className={classes.table} aria-label="Items">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>Config Type</TableCell>
                  <TableCell>Friendly Name</TableCell>
                  <TableCell>Friendly Description</TableCell>
                  <TableCell align="center">Ignored Questions</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientConfigData &&
                  clientConfigData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.configType.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.configType.userFriendlyName != null && row.configType.userFriendlyName.length > 0 ? "Defined" : "Undefined"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.configType.userFriendlyDescription != null && row.configType.userFriendlyDescription.length > 0 ? "Defined" : "Undefined"}
                      </TableCell>
                      <TableCell align="justify" className={classes.configTypeQuestionsColumn}>
                        {this.configTypeQuestionsToChips(row.configTypeQuestions)}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={(e) => handleEditClick(e, row.id)}
                          className={classes.menuButton}
                          size="small"
                          variant="outlined"
                          color="primary">
                          Edit
                        </Button>
                        <DeleteButton
                          onClick={(e) => handleClickDelete(e, row.id)}
                          startIcon={<DeleteIcon />}
                          size="small"
                          variant="outlined">
                          Delete
                        </DeleteButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={clientConfigData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Dialog
            open={deleteOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete config '<strong>{selectedItem && selectedItem.configType && selectedItem.configType.name}</strong>' ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="text">
                Cancel
              </Button>
              <DeleteButton startIcon={<DeleteIcon />} onClick={handleDelete} variant="outlined">
                DELETE
              </DeleteButton>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { clientconfigs } = state;
  return {
      loading: clientconfigs.loading,
  };
};

const mapDispatchToProps = {
  loadEditConfigItem,
  deleteConfig,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ClientConfigsItemsList));