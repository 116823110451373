import produce from 'immer';
import {
	APPROVALS_LOADING,
	APPROVAL_CLIENTS_LOADED,
	LOADING_ERROR,
	APPROVAL_DETAILS_LOADED,
	APPROVAL_STATUS_UPDATED,
	APPROVAL_SERVICES_UPDATED,
	SAVING_APPROVAL_PRODUCTS,
	APPROVAL_PRODUCTS_SAVED,
	APPROVER_LOCATION_UPDATED,
	APPROVER_ADDED_TO_LOCATION,
	APPROVERS_SAVED,
	SAVING_APPROVERS,
	APPROVER_CUSTOM_NOTE_SAVING,
	APPROVER_CUSTOM_NOTE_SAVED,
	APPROVER_CUSTOM_NOTE_UPDATE,
} from '../actions/types/approvalsTypes';

const initialState = {
	loading: true,
	savingProducts: false,
	savingCustomNote: false,
	clients: [],
	selectedClientContacts: [],
	selectedClientContactsForLocation: [],
	selectedApproverLocation: null,
};

const approvalsReducer = (state = initialState, action) => {
	switch (action.type) {
		case APPROVALS_LOADING:
			return Object.assign({}, state, {
				loading: true,
			});
		case APPROVAL_CLIENTS_LOADED:
			return Object.assign({}, state, {
				clients: action.payload,
				loading: false,
			});
		case LOADING_ERROR:
			return Object.assign({}, state, {
				loading: false,
			});
		case SAVING_APPROVAL_PRODUCTS:
			return Object.assign({}, state, {
				savingProducts: true,
			});
		case APPROVAL_PRODUCTS_SAVED:
			return Object.assign({}, state, {
				selectedClient: action.payload,
				savingProducts: false,
			});

		case APPROVER_CUSTOM_NOTE_SAVING:
			return Object.assign({}, state, {
				savingCustomNote: true,
			});
		case APPROVER_CUSTOM_NOTE_UPDATE:
			return produce(state, (draftState) => {
				draftState.selectedClient.customApprovalMessage = action.payload;
			});
		case APPROVER_CUSTOM_NOTE_SAVED:
			return produce(state, (draftState) => {
				draftState.savingCustomNote = false;
			});
		case APPROVER_LOCATION_UPDATED:
			return produce(state, (draftState) => {
				draftState.selectedApproverLocation = action.payload;
				if (action.payload !== null) {
					let location = draftState.selectedClient.locations.find((s) => s.connectWiseId === action.payload.connectWiseId);
					if (location === null) {
						draftState.selectedClientContactsForLocation = [];
					} else {
						draftState.selectedClientContactsForLocation = location.companyLocationApprovers;
					}
				} else {
					draftState.selectedClientContactsForLocation = [];
				}
			});
		case APPROVER_ADDED_TO_LOCATION:
			return produce(state, (draftState) => {
				draftState.selectedClientContactsForLocation = [...action.payload];
			});
		case APPROVERS_SAVED:
			return produce(state, (draftState) => {
				draftState.selectedClient = action.payload;
				draftState.savingApprovers = false;
			});
		case SAVING_APPROVERS:
			return Object.assign({}, state, {
				savingApprovers: true,
			});
		case APPROVAL_DETAILS_LOADED:
			return Object.assign({}, state, {
				selectedClient: action.payload.approvalData,
				selectedClientContacts: action.payload.contactData,
				selectedClientContactsForLocation: [],
				loading: false,
				savingProducts: false,
			});
		case APPROVAL_STATUS_UPDATED:
			return produce(state, (draftState) => {
				draftState.selectedClient.approvalRequired = action.payload.ApprovalEnabled;
			});
		case APPROVAL_SERVICES_UPDATED:
			return produce(state, (draftState) => {
				draftState.selectedClient.selectedProducts = [...action.payload];
			});
		default:
			return state;
	}
};

export default approvalsReducer;
