import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import NewsItemsList from "./NewsItemsList";
import { Link } from "react-router-dom";
import { loadNewsItems } from "../../actions/newsActions";
import Loading from "../Shared/Loading";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    margin: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  spacing: {
    margin: theme.spacing(4),
    marginLeft: theme.spacing(6),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const NewsContainer = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

class Newssettings extends Component {
  static propTypes = {};

  componentDidMount() {
    this.props.loadNewsItems();
  }
  render() {
    let { classes, loading, newsItems } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <NewsContainer>
        <Paper item xs={12} className={classes.root}>
          <Grid className={classes.root} container>
            <Grid item xs={12} className={classes.gridRoot}>
              <div className={classes.spacing}>
                <Typography variant="h5" className={classes.heading}>
                  Manage News
                </Typography>
                <Button component={Link} to="/Newssettings/new" variant="contained" color="primary">
                  Create News
                </Button>
                <NewsItemsList newsItems={newsItems} />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </NewsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { news } = state;
  return {
    loading: news.loading,
    newsItems: news.newsItems,
  };
};

const mapDispatchToProps = {
  loadNewsItems,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Newssettings));
