import {
	APPROVALS_LOADING,
	LOADING_ERROR,
	APPROVAL_CLIENTS_LOADED,
	APPROVAL_DETAILS_LOADED,
	APPROVAL_PRODUCTS_SAVED,
	APPROVAL_STATUS_UPDATED,
	APPROVAL_SERVICES_UPDATED,
	SAVING_APPROVAL_PRODUCTS,
	APPROVER_LOCATION_UPDATED,
	APPROVER_ADDED_TO_LOCATION,
	SAVING_APPROVERS,
	APPROVERS_SAVED,
	APPROVER_CUSTOM_NOTE_UPDATE,
	APPROVER_CUSTOM_NOTE_SAVING,
	APPROVER_CUSTOM_NOTE_SAVED,
} from './types/approvalsTypes';
import api from '../Services/api';
import { push } from 'connected-react-router';

export const approvalsLoading = () => {
	return {
		type: APPROVALS_LOADING,
	};
};

export const approvalClientsLoaded = (payload) => {
	return {
		type: APPROVAL_CLIENTS_LOADED,
		payload: payload,
	};
};

export const approvalClientDetailsLoaded = (payload) => {
	return {
		type: APPROVAL_DETAILS_LOADED,
		payload: payload,
	};
};

export const clientProductsSaved = (payload) => {
	return {
		type: APPROVAL_PRODUCTS_SAVED,
		payload: payload,
	};
};

export const clientApprovalSaved = (payload) => {
	return {
		type: APPROVERS_SAVED,
		payload: payload,
	};
};

export const loadingError = () => {
	return {
		type: LOADING_ERROR,
	};
};

export const approvalStatusUpdated = (payload) => {
	return {
		type: APPROVAL_STATUS_UPDATED,
		payload: payload,
	};
};

export const handleUpdateSelectedProducts = (payload) => {
	return {
		type: APPROVAL_SERVICES_UPDATED,
		payload: payload,
	};
};

export const handleUpdatedApproverLocation = (payload) => {
	return {
		type: APPROVER_LOCATION_UPDATED,
		payload: payload,
	};
};

export const handleAddSelectedApproverToLocation = (payload) => {
	return {
		type: APPROVER_ADDED_TO_LOCATION,
		payload: payload,
	};
};

export const savingApprovalProducts = () => {
	return {
		type: SAVING_APPROVAL_PRODUCTS,
	};
};

export const savingApprovers = () => {
	return {
		type: SAVING_APPROVERS,
	};
};

export const savingCustomNote = () => {
	return {
		type: APPROVER_CUSTOM_NOTE_SAVING,
	};
};

export const handleCustomNoteSaved = () => {
	return {
		type: APPROVER_CUSTOM_NOTE_SAVED,
	};
};

export const handleUpdateCustomApprovalMessage = (payload) => {
	return {
		type: APPROVER_CUSTOM_NOTE_UPDATE,
		payload: payload,
	};
};

export const handleSaveCustomApporovalNoteForCompany = (id, customNote) => async (dispatch) => {
	try {
		dispatch(savingCustomNote());

		let data = {
			CustomNoteMessage: customNote,
		};
		const res = await api.post(`/api/approval/clients/${id}/UpdateApprovalNote`, data);
		if (res.ok) {
			const data = await res.json();
			dispatch(handleCustomNoteSaved());
		}
	} catch (e) {}
};

export const handleSaveSelectedProducts = (id, selectedProducts) => async (dispatch) => {
	try {
		dispatch(savingApprovalProducts());

		let data = {
			SelectedProducts: selectedProducts,
		};
		const res = await api.post(`/api/approval/clients/${id}/UpdateApprovalProducts`, data);
		if (res.ok) {
			const data = await res.json();
			dispatch(clientProductsSaved(data));
		}
	} catch (error) {}
};

export const handleSaveSelectedApprovers = (id, locationId, approvalContacts) => async (dispatch) => {
	try {
		dispatch(savingApprovers());

		let data = {
			locationId: locationId,
			ApprovalContacts: approvalContacts,
		};
		const res = await api.post(`/api/approval/clients/${id}/UpdateApprovalContacts`, data);
		if (res.ok) {
			const data = await res.json();
			dispatch(clientApprovalSaved(data));
		}
	} catch (error) {}
};

export const loadApprovalClients = () => async (dispatch) => {
	try {
		dispatch(approvalsLoading());

		const res = await api.get('/api/approval/clients');
		const data = await res.json();

		dispatch(approvalClientsLoaded(data));
	} catch (e) {
		dispatch(loadingError());
	}
};

export const handleBackToApprovals = () => async (dispatch) => {
	dispatch(push('/Approvals'));
};

export const loadCompanyApprovalDetailsPage = (id) => async (dispatch) => {
	dispatch(approvalsLoading());
	dispatch(push(`/Approvals/${id}/Details`));
};

export const loadCompanyApprovalDetails = (id) => async (dispatch) => {
	try {
		dispatch(approvalsLoading());

		const reqApproval = api.get(`/api/approval/clients/${id}`);
		const reqContacts = api.get(`/api/approval/clients/${id}/contacts`);

		const [approvalResponse, contactsResponse] = await Promise.all([reqApproval, reqContacts]);

		const approvalData = await approvalResponse.json();
		const contactData = await contactsResponse.json();

		let data = {
			approvalData: approvalData,
			contactData: contactData,
		};

		dispatch(approvalClientDetailsLoaded(data));
	} catch (e) {
		dispatch(loadingError());
	}
};

export const handleApprovalRequiredChange = (id, status) => async (dispatch) => {
	try {
		let update = {
			Id: id,
			ApprovalEnabled: status,
		};

		const res = await api.post(`/api/approval/clients/${id}/updateApproval`, update);
		if (res.ok) {
			dispatch(approvalStatusUpdated(update));
		}
	} catch (e) {}
};
