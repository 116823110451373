import React, { Component, forwardRef } from "react";
import { connect } from "react-redux";
import { Dialog, Grid, DialogContent, DialogTitle, Button, CircularProgress } from "@material-ui/core";
import { handleCloseNewChat, handleLoadUserChatTickets, handleCreateNewChat } from "../../actions/chatActions";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Loading from "../Shared/Loading";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OfflineBolt from "@material-ui/icons/OfflineBolt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const styles = (theme) => ({
  mainDialog: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    padding: "25px",
  },
  actions: {
    paddingTop: "50px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class NewChatDialogWindow extends Component {
  handleCloseNewChat = () => {
    this.props.handleCloseNewChat();
  };

  render() {
    let { classes, showNewChat, userTickets, loading, loadingTickets } = this.props;

    if (loading) {
      return <Loading />;
    } else {
      return (
        <div>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={showNewChat}
            onEntering={() => this.props.handleLoadUserChatTickets()}
            onClose={this.handleCloseNewChat}
            aria-labelledby="form-dialog-title">
            <div className={classes.mainDialog}>
              <DialogTitle id="form-dialog-title">
                Select a Ticket to start a chat
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleCloseNewChat}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {loadingTickets ? (
                  <Grid container justify="center" alignItems="center" style={{ minHeight: "400px" }}>
                    <CircularProgress size={96} />
                  </Grid>
                ) : (
                  <MaterialTable
                    icons={tableIcons}
                    style={{ padding: "16px" }}
                    title=""
                    columns={[
                      { title: "Ticket", field: "ticketId", width: 100 },
                      { title: "Contact", field: "contact" },
                      { title: "Company", field: "company" },
                      { title: "Summary", field: "summary" },
                    ]}
                    data={userTickets}
                    options={{
                      sorting: true,
                      actionsCellStyle: { justifyContent: "center", width: 100 },
                    }}
                    actions={[
                      {
                        icon: "launch",
                        tooltip: "Start Chat",
                        onClick: (event, rowData) => {
                          this.props.handleCreateNewChat(rowData.onlineUserId, rowData.ticketId, rowData.summary);
                        },
                      },
                    ]}
                    components={{
                      Action: (props) =>
                        props.data.clickable ? (
                          <Button
                            onClick={(event) => props.action.onClick(event, props.data)}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                            size="small">
                            Start Chat
                          </Button>
                        ) : (
                          <Button
                            disabled={true}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                            size="small">
                            <OfflineBolt width={5} height={5} style={{ paddingRight: "5px" }} />
                            Offline
                          </Button>
                        ),
                    }}
                  />
                )}
              </DialogContent>
            </div>
          </Dialog>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { chat } = state;

  return {
    showNewChat: chat.showNewChat,
    userTickets: chat.userTickets,
    loadingTickets: chat.loadingTickets,
  };
};

const mapDispatchToProps = {
  handleCloseNewChat,
  handleLoadUserChatTickets,
  handleCreateNewChat,
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(NewChatDialogWindow)
);
