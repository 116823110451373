import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loading from '../Shared/Loading';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
	loadCompanyApprovalDetails,
	handleApprovalRequiredChange,
	handleUpdateSelectedProducts,
	handleSaveSelectedProducts,
	handleBackToApprovals,
	handleUpdatedApproverLocation,
	handleAddSelectedApproverToLocation,
	handleSaveSelectedApprovers,
	handleSaveCustomApporovalNoteForCompany,
	handleUpdateCustomApprovalMessage,
} from '../../actions/approvalsAction';
import { Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
	title: {
		flexGrow: 1,
	},
	menuButton: {
		margin: theme.spacing(2),
	},
	gridRoot: {
		minHeight: 'inherit',
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		minHeight: 'inherit',
	},
	spacing: {
		margin: theme.spacing(4),
		marginLeft: theme.spacing(6),
	},
	heading: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	headerItem: {
		margin: theme.spacing(1),
	},
	detailsItem: {
		margin: theme.spacing(4),
		width: '100%',
	},
	saveButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
});

const Container = styled.div`
	min-height: calc(100vh - 132px);
	overflow: hidden;
`;

class ApprovalDetails extends React.Component {
	componentDidMount() {
		this.props.loadCompanyApprovalDetails(this.props.match.params.id);
	}

	render() {
		const handleChange = (id, status) => {
			this.props.handleApprovalRequiredChange(id, status);
		};

		const handleBackClick = () => {
			this.props.handleBackToApprovals();
		};

		let {
			classes,
			loading,
			selectedClient,
			savingProducts,
			savingApprovers,
			savingCustomNote,
			selectedClientContacts,
			selectedApproverLocation,
			selectedClientContactsForLocation,
		} = this.props;
		if (loading) {
			return <Loading />;
		} else {
			return (
				<Container>
					<Paper item xs={12} className={classes.root}>
						<Grid className={classes.root} container>
							<Grid item sm={12} lg={8} className={classes.gridRoot}>
								<div className={classes.spacing}>
									<Typography variant='h5' className={classes.heading} gutterBottom>
										Approval Details for {selectedClient.name}
									</Typography>
									<Button color='primary' size='small' onClick={(e) => handleBackClick()}>
										Back
									</Button>
									<Divider variant='middle' light />
									<Grid item sm={12} className={classes.gridRoot}>
										<Grid item xs={12} className={classes.detailsItem}>
											<Typography variant='h6' className={classes.heading} gutterBottom>
												Approval Required
											</Typography>
											<FormControlLabel
												control={
													<Switch
														checked={selectedClient.approvalRequired}
														onChange={(e) => handleChange(selectedClient.id, !selectedClient.approvalRequired)}
														name='ApprovalRequired'
														color='primary'
													/>
												}
												label='Enabled'
											/>
										</Grid>
										<Divider variant='middle' light />
										<Grid item xs={12} className={classes.detailsItem}>
											<Typography variant='h6' className={classes.heading} gutterBottom>
												Custom Approval Required Message
											</Typography>
											<TextField
												style={{ width: '100%' }}
												label='Enter a custom approval message to display on tickets for this client'
												id='ApprovalRequiredNote'
												value={selectedClient.customApprovalMessage}
												onChange={(e) => this.props.handleUpdateCustomApprovalMessage(e.target.value)}
												multiline
												rowsMax={10}
												variant='outlined'
											/>
											<Button
												onClick={(e) => this.props.handleSaveCustomApporovalNoteForCompany(selectedClient.id, selectedClient.customApprovalMessage)}
												className={classes.saveButton}
												variant='contained'
												color='primary'
												disabled={savingCustomNote}>
												{savingCustomNote ? (
													<span>
														<CircularProgress size={20} style={{ marginRight: '5px' }} /> Saving...
													</span>
												) : (
													'Save'
												)}
											</Button>
										</Grid>
										<Divider variant='middle' light />
										<Grid item xs={12} className={classes.detailsItem}>
											<Typography variant='h6' className={classes.heading} gutterBottom>
												Services Requiring Approval
											</Typography>
											<Autocomplete
												multiple
												value={selectedClient.selectedProducts}
												onChange={(event, newValue) => {
													this.props.handleUpdateSelectedProducts(newValue);
												}}
												id='tags-outlined'
												options={selectedClient.allProducts ? selectedClient.allProducts : []}
												getOptionLabel={(option) => option.name}
												getOptionSelected={(option, value) => option.identifier === value.identifier}
												filterSelectedOptions
												renderInput={(params) => <TextField {...params} variant='outlined' label='Select Services' />}
											/>
											<Button
												onClick={(e) => this.props.handleSaveSelectedProducts(selectedClient.id, selectedClient.selectedProducts)}
												className={classes.saveButton}
												variant='contained'
												color='primary'
												disabled={savingProducts}>
												{savingProducts ? (
													<span>
														<CircularProgress size={20} style={{ marginRight: '5px' }} /> Saving...
													</span>
												) : (
													'Save'
												)}
											</Button>
										</Grid>
										<Divider variant='middle' light />
										<Grid item xs={12} className={classes.detailsItem}>
											<Typography variant='h6' className={classes.heading} gutterBottom>
												Manage Notifiable Approvers
											</Typography>
											<Autocomplete
												style={{ marginBottom: '16px', marginTop: '16px' }}
												id='tags-outlined'
												onChange={(event, newValue) => {
													this.props.handleUpdatedApproverLocation(newValue);
												}}
												options={selectedClient.locations ? selectedClient.locations : []}
												getOptionLabel={(option) => option.name}
												filterSelectedOptions
												renderInput={(params) => <TextField {...params} variant='outlined' label='Select Location' placeholder='Location' />}
											/>
											<Autocomplete
												style={{ marginBottom: '16px', marginTop: '16px' }}
												multiple
												id='tags-outlined'
												onChange={(event, newValue) => {
													this.props.handleAddSelectedApproverToLocation(newValue);
												}}
												value={selectedClientContactsForLocation ? selectedClientContactsForLocation : []}
												options={selectedClientContacts ? selectedClientContacts : []}
												getOptionLabel={(option) => `${option.firstName} ${option.lastName} `}
												getOptionSelected={(option, value) => option.connectWiseContactId === value.connectWiseContactId}
												filterSelectedOptions
												renderInput={(params) => <TextField {...params} variant='outlined' label='Add Notifiable Approvers for location' />}
											/>
											<Button
												onClick={(e) => this.props.handleSaveSelectedApprovers(selectedClient.id, selectedApproverLocation.id, selectedClientContactsForLocation)}
												disabled={savingApprovers || selectedApproverLocation == null}
												className={classes.saveButton}
												variant='contained'
												color='primary'>
												{savingApprovers ? (
													<span>
														<CircularProgress size={20} style={{ marginRight: '5px' }} /> Saving...
													</span>
												) : (
													'Save'
												)}
											</Button>
										</Grid>
									</Grid>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const { approvals } = state;
	return {
		selectedClient: approvals.selectedClient,
		selectedClientContacts: approvals.selectedClientContacts,
		loading: approvals.loading,
		savingProducts: approvals.savingProducts,
		savingApprovers: approvals.savingApprovers,
		savingCustomNote: approvals.savingCustomNote,
		selectedApproverLocation: approvals.selectedApproverLocation,
		selectedClientContactsForLocation: approvals.selectedClientContactsForLocation,
	};
};

const mapDispatchToProps = {
	loadCompanyApprovalDetails,
	handleApprovalRequiredChange,
	handleUpdateSelectedProducts,
	handleSaveSelectedProducts,
	handleBackToApprovals,
	handleUpdatedApproverLocation,
	handleAddSelectedApproverToLocation,
	handleSaveSelectedApprovers,
	handleSaveCustomApporovalNoteForCompany,
	handleUpdateCustomApprovalMessage,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ApprovalDetails));
