import React, { Component } from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Moment from "react-moment";
import styled from "styled-components";

const styles = (theme) => ({
  root: {
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:last-child": {
      paddingBottom: "5px",
    },
  },
  yourCard: {
    minWidth: 150,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "inline-block",
  },
  myCard: {
    minWidth: 150,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    display: "inline-block",
    float: "right",
  },
});

const UploadedImage = styled.img`
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoaded: false,
    };
  }

  componentDidMount() {
    if (this.props.message.isNew) {
      this.props.handleScrollToBottom(true);
    }
  }

  render() {
    const calendarStrings = {
      lastDay: "[Yesterday] LT",
      sameDay: "LT",
      nextDay: "[Tomorrow at] LT",
      lastWeek: "dddd LT",
      nextWeek: "dddd [at] LT",
      sameElse: "L",
    };

    let { classes, message, toName, fromUserId, fromId, handleLightBox, handleScrollToBottom, id } = this.props;

    if (fromUserId === fromId) {
      return (
        <React.Fragment>
          <Grid item xs={3} />
          <Grid item xs={8} id={id}>
            <Card className={classes.myCard}>
              <CardContent classes={{ root: classes.root }}>
                <Typography align="right" variant="caption">
                  <Moment style={{ opacity: 0.7 }} calendar={calendarStrings}>
                    {message.created}
                  </Moment>
                </Typography>
                <Typography variant="body1">{message.message}</Typography>
                {message.imageUrl !== null && (
                  <UploadedImage
                    onClick={() => handleLightBox(message.id)}
                    onLoad={() => handleScrollToBottom(this.props.message.isNew)}
                    alt="User uploaded"
                    src={message.imageUrl}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1} />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item xs={1} />
          <Grid item xs={8} id={id}>
            <Card className={classes.yourCard}>
              <CardContent classes={{ root: classes.root }}>
                <Typography display="inline" variant="caption">
                  {toName}
                </Typography>{" "}
                <Typography display="inline" variant="caption">
                  <Moment style={{ opacity: 0.7 }} calendar={calendarStrings}>
                    {message.created}
                  </Moment>
                </Typography>
                <Typography variant="body1" align="right">
                  {message.message}
                </Typography>
                {message.imageUrl !== null && (
                  <UploadedImage
                    onClick={() => handleLightBox(message.id)}
                    onLoad={() => handleScrollToBottom(this.props.message.isNew)}
                    alt="User uploaded"
                    src={message.imageUrl}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} />
        </React.Fragment>
      );
    }
  }
}

export default withStyles(styles)(ChatMessage);
