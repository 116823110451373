import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Lightbox from "react-image-lightbox";
import { Waypoint } from "react-waypoint";
import LoadingOverlay from "react-loading-overlay";
import TypingIndicator from "./TypingIndicator";
import ChatMessage from "./ChatMessage";
import ApprovalMessage from "./ApprovalMessage";
import { handleLoadMoreMessages } from "../../actions/chatActions";

import "react-image-lightbox/style.css";

const styles = (theme) => ({
  chatGrid: {
    minHeight: "inherit",
    maxHeight: "inherit",
    paddingTop: "20px",
  },
  scroll: {
    overflow: "scroll",
    overflowX: "hidden",
    flexGrow: 1,
    "&::-webkit-scrollbar": {
      width: "1em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
      outline: "1px solid slategrey",
    },
  },
  messageContainer: {
    marginLeft: "50px",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
});

const StyledLoader = styled(LoadingOverlay)`
  .MessageLoader_overlay {
    background: rgba(255, 0, 0, 0);
  }
  &.MessageLoader_wrapper--active {
    overflow: hidden;
  }
  .MessageLoader_content {
    color: #1f1f1f;
    background: white;
    border: 2px solid #0056b8;
    border-radius: 10px;
    padding: 20px;
    margin-top: 200px;
    font-size: 1.25rem;
  }
`;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class ChatMessages extends Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
    this.state = {
      lightBoxOpen: false,
      isLoading: false,
      listRendered: false,
      initialLoad: true,
    };
  }

  async componentDidMount() {
    this.props
      .handleLoadMoreMessages(this.props.conversationDetails.chatConversationId, this.props.conversationDetails.page)
      .then(async () => {
        this.scrollToBottom();
        sleep(750).then(() => {
          this.setState({
            listRendered: true,
            initialLoad: false,
          });
          this.scrollToBottom();
        });
      });
  }

  scrollToBottom = (override) => {
    if (this.state.initialLoad || override) {
      this.messagesEndRef &&
        this.messagesEndRef.current &&
        this.messagesEndRef.current.scrollIntoView({ behavior: "instant" });
    }
  };

  scrollToElem = (id) => {
    const elem = document.getElementById(id);
    if (!!elem) {
      elem.scrollIntoView();
    }
  };

  handleLightBox = (id) => {
    var message = this.props.conversationDetails.chatMessages.find((c) => c.id === id);
    if (message !== null) {
      this.setState({
        lightBoxOpen: true,
        lightBoxImg: message.imageUrl,
      });
    }
  };

  handleLoadItems = async () => {
    if (this.props.loadingMessages && this.state.listRendered) {
      return;
    }
    this.setState(
      {
        listRendered: false,
      },
      () => {
        this.props
          .handleLoadMoreMessages(
            this.props.conversationDetails.chatConversationId,
            this.props.conversationDetails.page
          )
          .then(async (pageSize) => {
            let lastMessage = this.props.conversationDetails.chatMessages[
              this.props.conversationDetails.chatMessages.length -
                (this.props.conversationDetails.chatMessages.length - pageSize - 1)
            ];

            if (this.props.conversationDetails.page === 1) {
              this.scrollToBottom();
              this.setState({
                listRendered: true,
              });
            } else {
              const elem = document.getElementById(lastMessage.id);
              if (!!elem) {
                elem.scrollIntoView();
                this.setState({
                  listRendered: true,
                  scrollToElemId: lastMessage.id,
                });
              }
            }
          });
      }
    );
  };

  renderWaypoint = () => {
    if (!this.props.loadingMessages && this.props.conversationDetails.hasMore) {
      return <Waypoint onEnter={() => this.handleLoadItems()} />;
    }
  };

  render() {
    let { classes, conversationDetails, loadingMessages, conversation, userName } = this.props;
    let { lightBoxOpen, lightBoxImg } = this.state;
    let toName = conversationDetails.toUser.preferredName;
    let fromUser = conversationDetails.fromUser;

    return (
      <div className={classes.scroll}>
        <StyledLoader
          active={loadingMessages}
          fadeSpeed={0}
          classNamePrefix="MessageLoader_"
          spinner={<CircularProgress />}
          text={<div>Loading additional messages...</div>}>
          <Grid container spacing={3} className={classes.chatGrid}>
            {this.renderWaypoint()}
            {conversationDetails.chatMessages.map((message) => {
              return (
                <ChatMessage
                  fromUserId={fromUser && fromUser.userId}
                  fromId={message && message.fromUserId}
                  id={message.id}
                  key={message.id}
                  message={message}
                  userName={userName}
                  toName={toName}
                  handleLightBox={this.handleLightBox}
                  handleScrollToBottom={this.scrollToBottom}
                />
              );
            })}
            <TypingIndicator conversation={conversation} handleScrollToBottom={() => this.scrollToBottom(true)} />
            {lightBoxOpen && (
              <Lightbox
                reactModalStyle={{
                  content: {
                    zIndex: 8888,
                  },
                }}
                mainSrc={lightBoxImg}
                onCloseRequest={() => this.setState({ lightBoxOpen: false })}
              />
            )}
            <div className={classes.messageContainer}>
              {conversationDetails.pendingApproval && (
                <ApprovalMessage
                  handleScrollToBottom={() => this.scrollToBottom(true)}
                  severity="info"
                  message="Waiting for Chat to be Accepted..."
                />
              )}
              {conversationDetails.callMe && (
                <ApprovalMessage
                    handleScrollToBottom={() => this.scrollToBottom(true)}
                    severity="warning"
                    message={`${toName} has requested you call them back instead.`}
                />
              )}
              {conversationDetails.denied && (
                <ApprovalMessage
                  handleScrollToBottom={() => this.scrollToBottom(true)}
                  severity="error"
                  message={`${toName} has denied chat request, try again later.`}
                />
              )}
              {conversationDetails.approved && (
                <ApprovalMessage
                  handleScrollToBottom={() => this.scrollToBottom(true)}
                  severity="success"
                  message={`${toName} has Accepted the Chat Request.`}
                />
              )}
            </div>
            <div ref={this.messagesEndRef} />
          </Grid>
        </StyledLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { chat, auth } = state;
  return {
    conversationDetails: chat.conversationDetails,
    conversation: chat.conversations.find((c) => c.chatConversationId === chat.conversationDetails.chatConversationId),
    userName: auth.account.userName,
    loading: chat.loading,
    loadingMessages: chat.loadingMessages,
  };
};

const mapDispatchToProps = {
  handleLoadMoreMessages,
};

// need to forwardRef options here with redux connect to expose ref for chatwindowdetail
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ChatMessages)
);
