import {
  CLIENTS_LOADING,
  CLIENTS_LOADED,
  CLIENTS_LOADED_ERROR,
  CLIENT_UPDATED_ERROR,
  CLIENT_UPDATED,
  UPDATE_BROADCAST,
  UPDATE_NOTIFICATIONS,
  UPDATE_TRACKING,
  UPDATE_AFTER_HOURS_WARNING,
  UPDATE_QUOTES_TO_MANAGEMENT,
  SAVING_OVERRIDE,
} from "./types/overrideTypes";
import api from "../Services/api";

export const loadingOverrides = () => {
  return {
    type: CLIENTS_LOADING,
  };
};

export const savingOverride = (id) => {
  return {
    type: SAVING_OVERRIDE,
    payload: id,
  };
};

export const clientOverridesLoaded = (payload) => {
  return {
    type: CLIENTS_LOADED,
    payload: payload,
  };
};

export const clientOverridesError = (payload) => {
  return {
    type: CLIENTS_LOADED_ERROR,
    payload: payload,
  };
};

export const clientOverrideUpdate = (payload) => {
  return {
    type: CLIENT_UPDATED,
    payload: payload,
  };
};

export const clientOverrideUpdateError = (payload) => {
  return {
    type: CLIENT_UPDATED_ERROR,
    payload: payload,
  };
};

export const updateBroadcast = (payload) => {
  return {
    type: UPDATE_BROADCAST,
    payload: payload,
  };
};

export const updateNotifications = (payload) => {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: payload,
  };
};

export const updateTracking = (payload) => {
  return {
    type: UPDATE_TRACKING,
    payload: payload,
  };
};

export const updateAfterHoursWarning = (payload) => {
    return {
        type: UPDATE_AFTER_HOURS_WARNING,
        payload: payload,
    };
};

export const updateQuotesToManagement = (payload) => {
    return {
        type: UPDATE_QUOTES_TO_MANAGEMENT,
        payload: payload
    }
}

export const loadClientOverrideSettings = () => async (dispatch) => {
  try {
    dispatch(loadingOverrides());
    const res = await api.get("/api/override/clients");
    const data = await res.json();
    dispatch(clientOverridesLoaded(data));
  } catch (error) {
    dispatch(clientOverridesError(error));
  }
};

export const handleSave = (id, payload) => async (dispatch) => {
  try {
    dispatch(savingOverride(id));
    const res = await api.post(`/api/override/clients/${id}`, {
      userTrackingEnabled: payload.userTrackingEnabled,
      notificationsEnabled: payload.notificationsEnabled,
      broadcastsEnabled: payload.broadcastsEnabled,
      afterHoursWarningEnabled: payload.afterHoursWarningEnabled,
      quotesToManagementEnabled: payload.quotesToManagementEnabled,
    });
    if (res.ok) {
      const data = await res.json();
      dispatch(clientOverrideUpdate(data));
    } else {
      dispatch(clientOverrideUpdateError());
    }
  } catch (error) {
    dispatch(clientOverrideUpdateError());
  }
};
