import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { ThemeProvider } from '@material-ui/styles';
import { store } from './store/configureStore';
import { authProvider, checkRole } from './Services/authProvider';
import Home from './components/Home';
import Chat from './components/Chat/Chat';
import Login from './components/Login';
import Settings from './components/Settings';
import Account from './components/Account/Account';
import NotFound from './components/NotFound';
import Newssettings from './components/News/Newssettings';
import NewsItemForm from './components/News/NewsItemForm';
import RouteWithLayout from './components/RouteWithLayout';
import Notifications from './components/Notifications/Notifications';
import ClientOverides from './components/ClientOverrides/ClientOverrides';
import Dashboard from './components/Dashboard/Dashboard';
import ClientUsage from './components/ClientUsage/ClientUsage';
import Approvals from './components/Approvals/Approvals';
import ApprovalDetails from './components/Approvals/ApprovalDetails';
import ManageAccounts from './components/Account/ManageAccounts';
import ClientConfigsSettings from './components/ClientConfigs/ClientConfigsSettings'
import ClientConfigsForm from './components/ClientConfigs/ClientConfigsForm';
import BannerSettings from './components/Banner/BannerSettings';
import BannerForm from './components/Banner/BannerForm';

import 'typeface-roboto-slab';
import getTheme from './theme/theme';


class App extends React.Component {
	constructor(props) {
		super(props);

		let theme = {
			palette: {
				type: 'light',
			},
			typography: { useNextVariants: true },
		};
		this.state = {
			theme: theme,
		};
	}

	render() {
		return (
			<AzureAD provider={authProvider} reduxStore={store}>
				{({ accountInfo, authenticationState, error }) => {
					let isAdmin = false;
					let isBroadcaster = false;
					let isNewsManager = false;
					let isApprover = false;
					if (authenticationState === AuthenticationState.Authenticated) {
						isAdmin = checkRole('Admin', accountInfo.account);
						isBroadcaster = checkRole('BroadcastUser', accountInfo.account);
						isNewsManager = checkRole('NewsManager', accountInfo.account);
						isApprover = checkRole('ApproverManager', accountInfo.account);
					}

					return (
						<ThemeProvider theme={getTheme(this.props.theme)}>
							<Switch>
								{authenticationState === AuthenticationState.Authenticated ? (
									<RouteWithLayout exact path='/' component={Home} layout={MainLayout} />
								) : (
									<RouteWithLayout exact path='/' component={Login} layout={MinimalLayout} />
								)}
								{isAdmin && <RouteWithLayout path='/overrides' component={ClientOverides} layout={MainLayout} />}
								<RouteWithLayout path='/Dashboard' component={Dashboard} layout={MainLayout} />
								<RouteWithLayout path='/ClientUsage' component={ClientUsage} layout={MainLayout} />
								{isAdmin || isBroadcaster ? <RouteWithLayout path='/Notifications' component={Notifications} layout={MainLayout} /> : null}
								{isAdmin || isNewsManager ? <RouteWithLayout exact path='/Newssettings' component={Newssettings} layout={MainLayout} /> : null}
								{isAdmin || isNewsManager ? <RouteWithLayout path='/Newssettings/New' component={NewsItemForm} layout={MainLayout} /> : null}
								{isAdmin || isNewsManager ? <RouteWithLayout path='/Newssettings/Edit/:id' component={NewsItemForm} layout={MainLayout} /> : null}
								{isAdmin || isApprover ? <RouteWithLayout path='/Approvals/:id/Details' component={ApprovalDetails} layout={MainLayout} /> : null}
								{isAdmin || isApprover ? <RouteWithLayout path='/Approvals' component={Approvals} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout exact path='/ClientConfigsSettings' component={ClientConfigsSettings} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout path='/ClientConfigsSettings/New' component={ClientConfigsForm} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout path='/ClientConfigsSettings/Edit/:id' component={ClientConfigsForm} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout path='/ManageAccounts' component={ManageAccounts} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout exact path='/BannerSettings' component={BannerSettings} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout path='/BannerSettings/New' component={BannerForm} layout={MainLayout} /> : null}
								{isAdmin ? <RouteWithLayout path='/BannerSettings/Edit/:id' component={BannerForm} layout={MainLayout} /> : null}
								<RouteWithLayout path='/Home' component={Home} layout={MainLayout} />
								<RouteWithLayout path='/Chat' component={Chat} layout={MainLayout} />
								<RouteWithLayout path='/Account' component={Account} layout={MainLayout} />
								<RouteWithLayout path='/Settings' component={Settings} layout={MainLayout} />
								<RouteWithLayout component={NotFound} exact layout={MinimalLayout} path='/not-found' />
								<Redirect to='/not-found' />
							</Switch>
						</ThemeProvider>
					);
				}}
			</AzureAD>
		);
	}
}

const mapStateToProps = (state) => {
	const { account } = state;
	return {
		theme: account.theme,
	};
};

export default connect(mapStateToProps, null)(App);
