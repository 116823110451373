import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import logo from "../../../../images/osit-logo.png";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
}));

const Logo = styled.img`
  max-width: 50px;
  margin: 10px;
  padding-right: 10px;
`;

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <RouterLink to="/">
          <Logo alt="Logo" src={logo} className={classes.img} />
        </RouterLink>
        <Typography variant="h5" className={classes.title}>
          Office Solutions IT
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
