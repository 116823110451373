import { CONFIG_TYPES_LOADING, CONFIG_TYPES_LOADED, CONFIG_QUESTIONS_LOADING, CONFIG_QUESTIONS_LOADED, CONFIG_DATA_LOADING, CONFIG_DATA_LOADED } from './types/clientConfigsTypes';
import api from '../Services/api';
import { push } from 'connected-react-router';

export const configTypesLoading = () => {
	return {
		type: CONFIG_TYPES_LOADING,
	};
};

export const configTypesLoaded = (payload) => {
	return {
		type: CONFIG_TYPES_LOADED,
		payload: payload,
	};
};

export const configQuestionsLoading = () => {
	return {
		type: CONFIG_QUESTIONS_LOADING,
	};
};

export const configQuestionsLoaded = (payload) => {
	return {
		type: CONFIG_QUESTIONS_LOADED,
		payload: payload,
	};
};

export const clientConfigDataLoading = () => {
	return {
		type: CONFIG_DATA_LOADING,
	};
};

export const clientConfigDataLoaded = (payload) => {
	return {
		type: CONFIG_DATA_LOADED,
		payload: payload,
	};
};

export const loadConfigTypes = () => async (dispatch) => {
	try {
		dispatch(configTypesLoading());

		const resConfigTypes = api.get('/api/Config/ConfigTypes');
		const [configTypesRes] = await Promise.all([resConfigTypes]);
		const configTypeData = await configTypesRes.json();

		dispatch(configTypesLoaded(configTypeData));
	} catch (e) {
		console.log(e);
	}
};

export const loadAllClientConfigSettings = () => async (dispatch) => {
	try {
		dispatch(clientConfigDataLoading());

		const resClientConfigs = api.get('/api/Config/ConfigTypes/ClientVisible');
		const [clientConfigsRes] = await Promise.all([resClientConfigs]);
		const clientConfigData = await clientConfigsRes.json();

		dispatch(clientConfigDataLoaded(clientConfigData));
	} catch (e) {
		console.log(e);
	}
};

export const loadClientConfigSetting = (id) => async (dispatch) => {
	try {
		dispatch(clientConfigDataLoading());

		const resClientConfig = api.get(`/api/Config/ConfigTypes/ClientVisible/${id}`);
		const [clientConfigRes] = await Promise.all([resClientConfig]);
		const clientConfigData = await clientConfigRes.json();

		dispatch(clientConfigDataLoaded(clientConfigData));
	} catch (e) {
		console.log(e);
	}
};

export const loadConfigQuestions = (configType) => async (dispatch) => {
	try {
		dispatch(configQuestionsLoading());

		if (configType != null) {
			const resConfigQuestions = api.get(`/api/Config/ConfigTypes/${configType.connectWiseId}/Questions/`);
			const [configQuestionsRes] = await Promise.all([resConfigQuestions]);
			const configQuestionData = await configQuestionsRes.json();
			dispatch(configQuestionsLoaded(configQuestionData));
		} else {
			dispatch(configQuestionsLoaded(null));
		}
	} catch (e) {
		console.log(e);
	}
};

export const handleAddConfig = (configData) => async (dispatch) => {
	try {
		console.log(configData);
		const res = await api.post('/api/Config/ConfigTypes/ClientVisible', configData);

		console.log(res);
		if (res.ok) {
			dispatch(push('/ClientConfigsSettings'));
		} else {
			// error
			throw res;
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const loadEditConfigItem = (id) => async (dispatch) => {
	dispatch(push(`/ClientConfigsSettings/Edit/${id}`));
};

export const deleteConfig = (id) => async (dispatch) => {
	try {
		dispatch(clientConfigDataLoading());
		var res = await api.deleteCall(`/api/Config/ConfigTypes/ClientVisible`, id);

		if (res.ok) {
			// Reload
			dispatch(loadAllClientConfigSettings());
		} else {
			// error
			throw res;
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const handleEditConfigItem = (id, configItem) => async (dispatch) => {
	try {
		const res = await api.post(`/api/Config/ConfigTypes/ClientVisible/${id}`, configItem);

		if (res.ok) {
			dispatch(push('/ClientConfigsSettings'));
		} else {
			// error
			throw res;
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};
