import { authProvider } from "./authProvider";
import config from "./config";

const get = async (url) => {
  const baseUrl = config.apiUrl;
  const apiScope = {
    scopes: [config.apiScope],
  };
  const token = await authProvider.getAccessToken(apiScope);

  return fetch(`${baseUrl}${url}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
    },
  });
};

const post = async (url, data) => {
  const baseUrl = config.apiUrl;
  const apiScope = {
    scopes: [config.apiScope],
  };

  const token = await authProvider.getAccessToken(apiScope);
  return fetch(`${baseUrl}${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
    },
  });
};

const postWithFile = async (url, formData) => {
  const baseUrl = config.apiUrl;
  const apiScope = {
    scopes: [config.apiScope],
  };

  const token = await authProvider.getAccessToken(apiScope);
  return fetch(`${baseUrl}${url}`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: "Bearer " + token.accessToken,
    },
  });
};

const deleteCall = async (url, id) => {
  const baseUrl = config.apiUrl;
  const apiScope = {
    scopes: [config.apiScope],
  };

  const token = await authProvider.getAccessToken(apiScope);
  return fetch(`${baseUrl}${url}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token.accessToken,
      "Content-Type": "application/json",
    },
  });
};

const getRoles = () => {
  const roles = authProvider.getAccount();
};

export default { get, getRoles, post, postWithFile, deleteCall };
