import { createMuiTheme } from '@material-ui/core';
import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

function getTheme(theme) {
  return createMuiTheme({
    palette: {
      type: theme.palette.type,
      black,
      white,
      primary: {
        contrastText: white,
        dark: colors.lightBlue[900],
        main: theme.palette.type === 'light' ? '#0056B8' : '#06a7e2',
        light: colors.lightBlue[100]
      },
      secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: theme.palette.type === 'light' ? '#06a7e2' : '#0056B8',
        light: colors.blue[100]
      },
      success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
      },
      info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400]
      },
      warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400]
      },
      error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
      },
      // text: {
      //   primary: colors.blueGrey[900],
      //   secondary: colors.blueGrey[600],
      //   link: colors.blue[600]
      // },
      background: {
        default: theme.palette.type === 'light' ? '#F4F6F8' : '#333333',
        paper: theme.palette.type === 'light' ? white : '#212121'
      },
      icon: colors.blueGrey[600],
      divider: colors.grey[200]
    },
    typography: {
      fontFamily: 'Roboto Slab, sans-serif'
    },
    //overrides,
    zIndex: {
      appBar: 1200,
      drawer: 1100
    }
  });
}

export default getTheme;
