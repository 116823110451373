import produce from "immer";
import {
  CLIENTS_LOADING,
  CLIENTS_LOADED,
  CLIENT_UPDATED,
  UPDATE_BROADCAST,
  UPDATE_NOTIFICATIONS,
  UPDATE_TRACKING,
  UPDATE_AFTER_HOURS_WARNING,
  UPDATE_QUOTES_TO_MANAGEMENT,
  SAVING_OVERRIDE,
} from "../actions/types/overrideTypes";

const initialState = {
  loading: true,
  clients: [],
};

const clientOverrideReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENTS_LOADING:
      return Object.assign({}, state, {
        loading: true,
      });
    case CLIENTS_LOADED:
      return Object.assign({}, state, {
        clients: action.payload,
        loading: false,
      });
    case SAVING_OVERRIDE:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].saving = true;
      });
    case CLIENT_UPDATED:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload.id);
        draftState.clients[index] = action.payload;
      });
    case UPDATE_BROADCAST:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].broadcastsEnabled = !draftState.clients[index].broadcastsEnabled;
      });
    case UPDATE_NOTIFICATIONS:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].notificationsEnabled = !draftState.clients[index].notificationsEnabled;
      });
    case UPDATE_TRACKING:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].userTrackingEnabled = !draftState.clients[index].userTrackingEnabled;
      });
    case UPDATE_AFTER_HOURS_WARNING:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].afterHoursWarningEnabled = !draftState.clients[index].afterHoursWarningEnabled;
      });
    case UPDATE_QUOTES_TO_MANAGEMENT:
      return produce(state, (draftState) => {
        var index = draftState.clients.findIndex((client) => client.id === action.payload);
        draftState.clients[index].quotesToManagementEnabled = !draftState.clients[index].quotesToManagementEnabled;
      });
    default:
      return state;
  }
};

export default clientOverrideReducer;
