import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	root: {
		marginRight: theme.spacing(10),
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

const ApprovalMessage = ({ severity, message, handleScrollToBottom }) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);

	useEffect(() => {
		if (severity === 'success') {
			const timer = setTimeout(() => {
				setOpen(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [severity]);

	useEffect(() => {
		handleScrollToBottom();
	}, []);

	return (
		<div className={classes.root}>
			<Collapse in={open}>
				<Alert
					variant='filled'
					severity={severity}
					action={
						<IconButton
							aria-label='close'
							color='inherit'
							size='small'
							onClick={() => {
								setOpen(false);
							}}>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					}>
					{message}
				</Alert>
			</Collapse>
		</div>
	);
};

export default ApprovalMessage;
