import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Paper, Typography } from "@material-ui/core";
import Loading from "../Shared/Loading";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { loadApprovalClients } from "../../actions/approvalsAction";
import ApprovalsList from "./ApprovalsList";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    margin: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  spacing: {
    margin: theme.spacing(4),
    marginLeft: theme.spacing(6),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerItem: {
    margin: theme.spacing(1),
  },
});

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

export class Approvals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.loadApprovalClients();
  }

  render() {
    let { classes, loading, clients } = this.props;

    if (loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <Paper item xs={12} className={classes.root}>
            <Grid className={classes.root} container>
              <Grid item sm={12} className={classes.gridRoot}>
                <div className={classes.spacing}>
                  <Typography variant="h5" className={classes.heading} gutterBottom>
                    Client Approval Required Settings
                  </Typography>
                  <ApprovalsList clients={clients} />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { approvals } = state;
  return {
    clients: approvals.clients,
    loading: approvals.loading,
  };
};

const mapDispatchToProps = { loadApprovalClients };

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Approvals));
