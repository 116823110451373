import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Grid, Typography, Button, LinearProgress, Paper, FormControlLabel, Switch, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { DropzoneDialog } from "material-ui-dropzone";
import { loadAccountSettings, saveAccountSettings, handleSaveAcknowledged } from "../../actions/accountActions";
import Loading from "../Shared/Loading";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  menuButton: {
    margin: theme.spacing(2),
  },
  gridRoot: {
    minHeight: "inherit",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
  },
  textField: {
    margin: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(4),
  },
  spacing: {
    margin: theme.spacing(2),
  },
  spacingImg: {
    margin: theme.spacing(4),
  },
  heading: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  snackBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
});

const Container = styled.div`
  min-height: calc(100vh - 132px);
  overflow: hidden;
`;

const DisplayImage = ({ fileImage, profileImage }) => {
  if (fileImage) {
    return <img src={fileImage} width="200" alt="User Profile" />;
  } else if (profileImage) {
    return <img src={`data:image/jpeg;base64,${profileImage.image}`} width="200" alt="User Profile" />;
  }

  return null;
};
export class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.props.loadAccountSettings();
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSave = (files) => {
    //Saving files to state for further use and closing Modal.
    this.setState({
      files: files,
      open: false,
      fileImage: URL.createObjectURL(files[0]),
    });
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseModal = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.handleSaveAcknowledged();
  };

  render() {
    let { classes, loading, settings, success } = this.props;
    let { files, fileImage } = this.state;
    const formikRef = React.createRef();

    // see https://github.com/jaredpalmer/formik/issues/33
    const onSubmit = async (values) => {
      try {
        await this.props.saveAccountSettings(values, this.state.files);
      } catch (apiException) {
        if (apiException.response && apiException.response.data && apiException.response.data.error) {
          formikRef.current.setErrors(apiException.response.data.error);
        } else {
          console.error(apiException); // or some other fallback handling
        }
      }
    };

    const cancelCreate = () => {};

    if (loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <Paper item xs={12} className={classes.root}>
            <Grid className={classes.root} container>
              <Grid item xs={8} className={classes.gridRoot}>
                <Formik
                  onSubmit={async (values, actions) => {
                    await onSubmit(values);
                    actions.setSubmitting(false);
                  }}
                  cancelCreate={cancelCreate}
                  files={files}
                  childRef={(ref) => (this.child = formikRef)}
                  initialValues={{ darkMode: settings.darkMode }}>
                  {({ isSubmitting, submitForm, values, setFieldValue }) => (
                    <Form className={classes.form}>
                      <div>
                        <Typography className={classes.heading} variant="h5">
                          Update Account
                        </Typography>
                      </div>
                      <label>
                        <div>
                          <FormControlLabel
                            className={classes.spacing}
                            checked={values.darkMode}
                            onChange={() => setFieldValue("darkMode", !values.darkMode)}
                            control={<Switch color="primary" />}
                            label="Enable Dark Mode"
                          />
                        </div>
                        <div className={classes.spacing}>
                          <label>
                            <Typography>Current Profile Picture</Typography>
                          </label>
                          <DisplayImage fileImage={fileImage} profileImage={settings.profileImage} />
                        </div>
                        <Button
                          className={classes.menuButton}
                          color="primary"
                          variant="outlined"
                          onClick={this.handleOpen}>
                          {settings.profileImage ? "Update Image" : "Add Image"}
                        </Button>
                        <DropzoneDialog
                          open={this.state.open}
                          onSave={this.handleSave}
                          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                          showPreviews={true}
                          showFileNames={true}
                          dropzoneText="Drag and drop an image file here or click here."
                          maxFileSize={5000000}
                          filesLimit={1}
                          onClose={this.handleClose}
                        />
                        {isSubmitting && <LinearProgress />}
                        <br />
                        <br />
                        <Button
                          className={classes.menuButton}
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}>
                          SAVE
                        </Button>
                      </label>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.snackBar}>
            {success && (
              <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }} 
              onClose={this.handleCloseModal} 
              open={success} autoHideDuration={8000}>
                <Alert onClose={this.handleCloseModal} severity="success">
                  Account Settings Saved!
                </Alert>
              </Snackbar>
            )}
          </div>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    loading: account.loading,
    errorLoading: account.errorLoading,
    saving: account.saving,
    errorSaving: account.errorSaving,
    settings: account.settings,
    success: account.success,
  };
};

const mapDispatchToProps = {
  saveAccountSettings,
  loadAccountSettings,
  handleSaveAcknowledged,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Account));