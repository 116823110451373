import {
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_LOADED,
  NOTIFICATIONS_SENDING_ERROR,
  NOTIFICATIONS_SENDING_SUCCESS,
  NOTIFICATIONS_MESSAGE_CLOSE,
} from "./types/notificationTypes";
import api from "../Services/api";

export const loadNotificationSettings = () => async (dispatch) => {
  try {
    dispatch(notificationsLoading());
    const clients = api.get("/api/notification/clients");
    const recipients = api.get("/api/notification/recipients");
    const online = api.get("/api/notification/online");

    const [clientsRes, recipientRes, onlineRes] = await Promise.all([clients, recipients, online]);
    const clientData = await clientsRes.json();
    const recipientData = await recipientRes.json();
    const onlineData = await onlineRes.json();

    let payload = {
      clientData,
      recipientData,
      onlineData,
    };
    dispatch(notificationsLoaded(payload));
  } catch (e) {
    console.log(e);
    //dispatch(loadingError());
  }
};

export const notificationsLoading = () => {
  return {
    type: NOTIFICATIONS_LOADING,
  };
};

export const notificationsLoaded = (payload) => {
  return {
    type: NOTIFICATIONS_LOADED,
    payload: payload,
  };
};

export const newMessageSendError = () => {
  return {
    type: NOTIFICATIONS_SENDING_ERROR,
  };
};

export const newMessageSendSuccess = (payload) => {
  return {
    type: NOTIFICATIONS_SENDING_SUCCESS,
    payload: payload,
  };
};

export const handleCloseSnackbar = () => {
  return {
    type: NOTIFICATIONS_MESSAGE_CLOSE,
  };
};

export const handleNewBroadcast = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/api/notification/broadcast", payload);
    if (res.ok) {
      const data = await res.json();
      dispatch(newMessageSendSuccess(data));
    } else {
      dispatch(newMessageSendError());
    }
  } catch (err) {
    dispatch(newMessageSendError());
  }
};
