export const LOADING_USERS = "LOADING_USERS";
export const LOADING_ERROR = "LOADING_ERROR";
export const CHAT_USERS_LOADED = "CHAT_USERS_LOADED";
export const CHAT_WINDOW_LOADING = "CHAT_WINDOW_LOADING";
export const LOADING_CHAT_DETAILS = "LOADING_CHAT_DETAILS";
export const CHAT_DETAILS_LOADED = "CHAT_DETAILS_LOADED";
export const ERROR_SENDING_MESSAGE = "ERROR_SENDING_MESSAGE";
export const SENDING_NEW_MESSAGE = "SENDING_NEW_MESSAGE";
export const NEW_MESSAGE_SENT = "NEW_MESSAGE_SENT";
export const NEW_MESSAGE_RECEIVED = "NEW_MESSAGE_RECEIVED";
export const ACK_MESSAGE_RECEIVED = "ACK_MESSAGE_RECEIVED";
export const MESSAGE_ACK_PENDING = "MESSAGE_ACK_PENDING";
export const SHOW_NEW_CHAT = "SHOW_NEW_CHAT";
export const HIDE_NEW_CHAT = "HIDE_NEW_CHAT";
export const SHOW_NEW_TICKET = "SHOW_NEW_TICKET";
export const HIDE_NEW_TICKET = "HIDE_NEW_TICKET";
export const CREATE_NEW_CHAT = "CREATE_NEW_CHAT";
export const TYPING_INDICATOR_UPDATE = "TYPING_INDICATOR_UPDATE";
export const HUB_DISCONNECTED = "HUB_DISCONNECTED";
export const HUB_RECONNECTED = "HUB_RECONNECTED";
export const HUB_CONNECTED = "HUB_CONNECTED";
export const PROCESS_LOGIN = "PROCESS_LOGIN";
export const PRESENCESTATUS_UPDATE = "PRESENCESTATUS_UPDATE";
export const DESKTOP_NOTIFICATION_SENT = "DESKTOP_NOTIFICATION_SENT";
export const VISIBILITY_CHANGED = "VISIBILITY_CHANGED";
export const CHAT_CONVO_UPDATE = "CHAT_CONVO_UPDATE";
export const LOADING_ADDITION_MESSAGES = "LOADING_ADDITION_MESSAGES";
export const ADDITIONAL_MESSAGES_LOADED = "ADDITIONAL_MESSAGES_LOADED";
export const ADDITIONAL_MESSAGES_ERROR = "ADDITIONAL_MESSAGES_ERROR";
export const LOADING_USER_TICKETS = "LOADING_USER_TICKETS";
export const LOADED_USER_TICKETS = "LOADED_USER_TICKETS";
export const LOADING_NEW_TICKET_SETTINGS = "LOADING_NEW_TICKET_SETTINGS";
export const LOADED_NEW_TICKET_SETTINGS = "LOADED_NEW_TICKET_SETTINGS";
export const NEW_TICKET_CREATED = "NEW_TICKET_CREATED";
export const CREATE_NEW_TICKET = "CREATE_NEW_TICKET";
export const NEW_TICKET_CREATED_ACK = "NEW_TICKET_CREATED_ACK";
export const POSTING_TICKET_NOTE = "POSTING_TICKET_NOTE";
export const TICKET_NOTE_POSTED = "TICKET_NOTE_POSTED";
export const ERROR_POSTING_TICKET_NOTE = "ERROR_POSTING_TICKET_NOTE";
export const CHAT_SNACKBAR_CLOSE = "CHAT_SNACKBAR_CLOSE";