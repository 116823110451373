import React, { Component } from "react";

class Settings extends Component {
  static propTypes = {};

  render() {
    return <div>settings page here</div>;
  }
}

export default Settings;
