import {
  SHOW_BANNER_DIALOG,
  CLOSE_BANNER_DIALOG,
  BANNERS_LOADED,
  BANNERS_LOADING,
  BANNER_CREATED,
  BANNERS_CLIENTS_LOADED
} from "./types/bannerTypes";
import api from "../Services/api";
import { push } from "connected-react-router";

export const handleClickNewBanner = () => {
  return {
    type: SHOW_BANNER_DIALOG,
  };
};

export const handleCloseNewBanners = () => {
  return {
    type: CLOSE_BANNER_DIALOG,
  };
};

export const bannersLoading = () => {
  return {
    type: BANNERS_LOADING,
  };
};

export const bannersLoaded = (payload) => {
  return {
    type: BANNERS_LOADED,
    payload: payload,
  };
};

export const clientsLoaded = (payload) => {
  return {
    type: BANNERS_CLIENTS_LOADED,
    payload: payload
  };
}
export const loadBanners = () => async (dispatch) => {
  try {
    dispatch(bannersLoading());

    const resBanners = api.get("/api/banner/All");
    const resClients = api.get("/api/banner/clients");

    const [bannerRes, clientRes] = await Promise.all([resBanners, resClients]);

    const bannerData = await bannerRes.json();
    const clientData = await clientRes.json();
    dispatch(bannersLoaded(bannerData));
    dispatch(clientsLoaded(clientData));

  } catch (e) {
    console.log(e);
  }
};

export const loadEditBannerItem = (id) => async (dispatch) => {
  dispatch(push(`/BannerSettings/Edit/${id}`));
};

export const deleteBanner = (id) => async (dispatch) => {
  try {
    dispatch(bannersLoading());
    var res = await api.deleteCall("/api/banner/delete", id);

    if (res.ok) {
      dispatch(loadBanners());
    } else {
    }
  } catch (e) {}
};

export const handleEditBanner = (id, banner) => async (dispatch) => {
  try {

    const res = await api.post(`/api/banner/edit/${id}`, banner);

    if (res.ok) {
      dispatch(push("/BannerSettings"));
    } else {
      // error
    }
  } catch (e) {
    console.log(e);
    //dispatch(loadingError());
  }
};

export const handleCreateBanner = (banner) => async (dispatch) => {
  try {
    const res = await api.post("/api/banner/create", banner);

    if (res.ok) {
      dispatch(push("/BannerSettings"));
    } else {
      // error
    }
  } catch (e) {
    console.log(e);
    //dispatch(loadingError());
  }
};
