import {
	LOADING_USERS,
	LOADING_CHAT_DETAILS,
	CHAT_USERS_LOADED,
	CHAT_DETAILS_LOADED,
	ERROR_SENDING_MESSAGE,
	NEW_MESSAGE_SENT,
	SENDING_NEW_MESSAGE,
	NEW_MESSAGE_RECEIVED,
	MESSAGE_ACK_PENDING,
	SHOW_NEW_CHAT,
	HIDE_NEW_CHAT,
	TYPING_INDICATOR_UPDATE,
	HUB_RECONNECTED,
	HUB_DISCONNECTED,
	HUB_CONNECTED,
	PRESENCESTATUS_UPDATE,
	DESKTOP_NOTIFICATION_SENT,
	VISIBILITY_CHANGED,
	CHAT_CONVO_UPDATE,
	LOADING_ADDITION_MESSAGES,
	ADDITIONAL_MESSAGES_LOADED,
	LOADING_USER_TICKETS,
	LOADED_USER_TICKETS,
	SHOW_NEW_TICKET,
	HIDE_NEW_TICKET,
	LOADED_NEW_TICKET_SETTINGS,
	LOADING_NEW_TICKET_SETTINGS,
	NEW_TICKET_CREATED,
	CREATE_NEW_TICKET,
	NEW_TICKET_CREATED_ACK,
	TICKET_NOTE_POSTED,
	ERROR_POSTING_TICKET_NOTE,
	CHAT_SNACKBAR_CLOSE,
} from '../actions/types/chatTypes';
import produce from 'immer';

const initialState = {
	users: null,
	conversations: null,
	conversationDetails: null,
	page: 0,
	loading: false,
	loadingMessages: false,
	loadingTickets: false,
	sendingMessage: false,
	loadingNewTicket: false,
	creatingNewTicket: false,
	hubStatus: 0,
	totalUnreadMessageCount: 0,
	acks: [],
	browserNotifications: [],
	unreadMessages: [],
	userTickets: [],
	priorities: [],
	ticketCreated: null,
	visibility: 'visible',
};

const chatReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_USERS:
			return Object.assign({}, state, {
				loading: true,
			});
		case LOADING_ADDITION_MESSAGES:
			return Object.assign({}, state, {
				loadingMessages: true,
			});
		case LOADING_CHAT_DETAILS:
			return Object.assign({}, state, {
				loading: true,
				loadingMessages: true,
			});
		case LOADING_USER_TICKETS:
			return Object.assign({}, state, {
				loadingTickets: true,
			});
		case LOADED_USER_TICKETS:
			return Object.assign({}, state, {
				userTickets: action.payload,
				loadingTickets: false,
			});
		case CHAT_DETAILS_LOADED:
			return produce(state, (draftState) => {
				draftState.conversationDetails = action.payload;
				draftState.conversationDetails.hasMoreMessages = true;
				draftState.conversationDetails.pendingApproval = false;
				draftState.conversationDetails.denied = false;
				draftState.conversationDetails.approved = false;
				draftState.conversationDetails.callMe = false;
				draftState.loading = false;
				var index = draftState.conversations.findIndex((convo) => convo.chatConversationId === action.payload.chatConversationId);
				if (index >= 0) {
					draftState.conversations[index].unreadMessageCount = action.payload.unreadMessageCount;
				}
				draftState.totalUnreadMessageCount = action.payload.totalUnreadMessageCount;
			});
		case VISIBILITY_CHANGED:
			return produce(state, (draftState) => {
				draftState.visibility = action.payload;
				if (draftState.visibility === 'visible') {
					draftState.totalUnreadMessageCount = 0;
					if (
						draftState.conversations !== undefined &&
						draftState.conversations !== null &&
						draftState.conversationDetails != null &&
						draftState.conversationDetails !== undefined
					) {
						var index = draftState.conversations.findIndex((convo) => convo.chatConversationId === draftState.conversationDetails.chatConversationId);
						if (index >= 0) {
							draftState.conversations[index].unreadMessageCount = 0;
						}
					}
				}
			});
		case TICKET_NOTE_POSTED:
			return Object.assign({}, state, {
				ticketNotePosted: true,
			});
		case ERROR_POSTING_TICKET_NOTE:
			return Object.assign({}, state, {
				ticketNotePostedError: true,
			});
		case CHAT_SNACKBAR_CLOSE:
			return Object.assign({}, state, {
				ticketNotePosted: false,
				ticketNotePostedError: false,
			});
		case SHOW_NEW_TICKET:
			return Object.assign({}, state, {
				showNewTicket: true,
			});
		case HIDE_NEW_TICKET:
			return Object.assign({}, state, {
				showNewTicket: false,
			});
		case LOADING_NEW_TICKET_SETTINGS:
			return Object.assign({}, state, {
				loadingNewTicket: true
			});
		case LOADED_NEW_TICKET_SETTINGS:
			return Object.assign({}, state, {
				priorities: action.payload,
				loadingNewTicket: false
			});
		case CREATE_NEW_TICKET:
			return Object.assign({}, state, {
				loadingNewTicket: true
			});
		case NEW_TICKET_CREATED:
			return Object.assign({}, state, {
				ticketCreated: action.payload,
				loadingNewTicket: false,
			});
		case NEW_TICKET_CREATED_ACK:
			return Object.assign({}, state, {
				ticketCreated: null,
			});
		case SHOW_NEW_CHAT:
			return Object.assign({}, state, {
				showNewChat: true,
				userTickets: [],
			});
		case HIDE_NEW_CHAT:
			return Object.assign({}, state, {
				showNewChat: false,
			});
		case CHAT_USERS_LOADED:
			return Object.assign({}, state, {
				conversations: action.payload.conversations,
				totalUnreadMessageCount: action.payload.totalUnreadMessageCount,
				loading: false,
			});
		case NEW_MESSAGE_SENT:
			return produce(state, (draftState) => {
				draftState.sendingMessage = false;
				draftState.conversationDetails.chatMessages.push(action.payload);
			});
		case SENDING_NEW_MESSAGE:
			return Object.assign({}, state, {
				sendingMessage: true,
			});
		case ERROR_SENDING_MESSAGE:
			return Object.assign({}, state, {
				sendingMessage: false,
			});
		case ADDITIONAL_MESSAGES_LOADED:
			return produce(state, (draftState) => {
				if (draftState.conversationDetails && draftState.conversationDetails.chatConversationId === action.payload.chatConversationId) {
					draftState.conversationDetails.chatMessages = [...action.payload.messages, ...draftState.conversationDetails.chatMessages];
					draftState.conversationDetails.hasMore = action.payload.hasMore;
					draftState.loadingMessages = false;
					draftState.conversationDetails.page = draftState.conversationDetails.page ? draftState.conversationDetails.page + 1 : 1;
				}
			});
		case NEW_MESSAGE_RECEIVED:
			return produce(state, (draftState) => {
				if (draftState.conversationDetails && draftState.conversationDetails.chatConversationId === action.payload.data.chatConversationId) {
					// mark the new message so the chatlist knows to scroll it into view
					action.payload.data.isNew = true;
					draftState.conversationDetails.chatMessages.push(action.payload.data);
				}
			});
		case MESSAGE_ACK_PENDING:
			return produce(state, (draftState) => {
				draftState.acks.push(action.payload);
				draftState.totalUnreadMessageCount++;
				if (draftState.conversations !== null) {
					draftState.conversations.map((convo) => {
						if (convo.chatConversationId === action.payload.conversationId) {
							convo.unreadMessageCount++;
						}

						return convo;
					});
				}
				draftState.browserNotifications.push({
					id: action.payload.id,
					body: `${action.payload.message.message}`,
					title: `New Message from ${action.payload.message.from}`,
				});
			});
		case DESKTOP_NOTIFICATION_SENT:
			return produce(state, (draftState) => {
				draftState.browserNotifications = draftState.browserNotifications.filter((notify) => notify.id !== action.payload);
			});
		case CHAT_CONVO_UPDATE:
			return produce(state, (draftState) => {
				if (draftState.conversationDetails && draftState.conversationDetails.chatConversationId === action.payload.ChatConversationId) {
					draftState.conversationDetails.pendingApproval = action.payload.PendingApproval;
					draftState.conversationDetails.approved = action.payload.Approved;
					draftState.conversationDetails.denied = action.payload.Denied;
					draftState.conversationDetails.callMe = action.payload.CallMe;
				}
			});
		case PRESENCESTATUS_UPDATE:
			return produce(state, (draftState) => {
				if (draftState.conversations !== null) {
					draftState.conversations.map((convo) => {
						if (convo.chatConversationId === action.payload.ConversationId) {
							convo.fromUserConnectionStatus = action.payload.ConnectionStatus;
						}
						return convo;
					});
				}
			});
		case TYPING_INDICATOR_UPDATE:
			return produce(state, (draftState) => {
				if (draftState.conversations !== null) {
					draftState.conversations.map((convo) => {
						if (convo.chatConversationId === action.payload.ConversationId) {
							convo.typingIndicator = action.payload;
						}
						return convo;
					});
				}
			});
		case HUB_CONNECTED:
			return Object.assign({}, state, {
				hubStatus: 1,
			});
		//TODO: Show message user back online, update connectionId from payload
		case HUB_RECONNECTED:
			return Object.assign({}, state, {
				hubStatus: 1,
			});
		// TODO: Show emssage user offline, disable messaging etc.
		case HUB_DISCONNECTED:
			return Object.assign({}, state, {
				hubStatus: 0,
			});
		default:
			return state;
	}
};

export default chatReducer;
