import React, { Component } from "react";
import {
  Typography,
  Button,
  MenuItem,
  List,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { connect } from "react-redux";
import { LoadChatDetailsForUser, loadChatDetailWindow, handleClickNewChat } from "../../actions/chatActions";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const styles = (theme) => ({
  chatSideBar: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
    paddingTop: "15px",
    borderRight: theme.palette.type === "light" ? "1px solid #eeeeee" : "1px solid #333333",
  },
  chatSideBarLoading: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "inherit",
    borderRight: theme.palette.type === "light" ? "1px solid #eeeeee" : "1px solid #333333",
    paddingTop: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    backgroundColor: theme.palette.type === "light" ? "#eeeeee" : "#333333",
  },
  badge: {
    top: 0,
    left: "15px",
  },
});

const handleStatusType = (status) => {
  switch (status) {
    case 0:
      return "darkred";
    case 1:
      return "darkgreen";
    case 2:
      return "orange";
    default:
      return "darkred";
  }
};

const UserStatus = styled(FiberManualRecordIcon)`
  float: right;
  vertical-align: middle;
  color: ${({ status }) => handleStatusType(status)};
`;

export class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  componentDidMount() {
    this.props.LoadChatDetailsForUser();
  }

  handleLoadChatDetail = (id) => {
    this.setState({
      selected: id,
    });
    this.props.loadChatDetailWindow(id);
  };

  handleClickNewChat = () => {
    this.props.handleClickNewChat();
  };

  render() {
    let { classes, conversations } = this.props;
    const { selected } = this.state;

    return (
      <List className={classes.chatSideBar}>
        <MenuItem>
          <ListItemText primary=" "></ListItemText>
          <Button variant="contained" color="primary" onClick={this.handleClickNewChat}>
            New Chat
          </Button>
        </MenuItem>
        {conversations &&
          conversations.map((conversation) => {
            return (
              <React.Fragment key={conversation.chatConversationId}>
                {conversation.unreadMessageCount > 0 ? (
                  <Badge
                    classes={{
                      badge: classes.badge,
                    }}
                    style={{ width: "100%" }}
                    badgeContent={conversation.unreadMessageCount}
                    color="error"
                    className={classes.item}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}>
                    <MenuItem
                      style={{ width: "100%" }}
                      key={conversation.chatConversationId}
                      button
                      onClick={() => this.handleLoadChatDetail(conversation.chatConversationId)}
                      selected={selected === conversation.chatConversationId}>
                      <ListItemAvatar>
                        <Avatar alt={conversation.toUser.preferredName} src="/static/images/avatar/1.jpg" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                              {conversation.toUser.preferredName}
                              {conversation && <UserStatus status={conversation.fromUserConnectionStatus} />}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </MenuItem>
                  </Badge>
                ) : (
                  <MenuItem
                    key={conversation.chatConversationId}
                    button
                    onClick={() => this.handleLoadChatDetail(conversation.chatConversationId)}
                    selected={selected === conversation.chatConversationId}>
                    <ListItemAvatar>
                      <Avatar alt={conversation.toUser.preferredName} src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                            {conversation.toUser.preferredName}
                            {conversation && <UserStatus status={conversation.fromUserConnectionStatus} />}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </MenuItem>
                )}
                <Divider className={classes.divider} component="li" />
              </React.Fragment>
            );
          })}
      </List>
    );
  }
}

const mapStateToProps = (state) => {
  const { chat } = state;

  return {
    conversations: chat.conversations,
    loading: chat.loading,
  };
};

const mapDispatchToProps = {
  LoadChatDetailsForUser,
  loadChatDetailWindow,
  handleClickNewChat,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ChatList));
